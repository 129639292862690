import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Dropdown } from 'semantic-ui-react'

export default class MenuLeft extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      isSubMenu: 'service', //service addon systen
      isExpand: 'dashboard',
    };
  }

  expandMenu(v) {
    this.setState({
      isExpand: this.state.isExpand==v ? '' : v
    })
  }

  expandSubMenu(v) {
    this.setState({
      isSubMenu: this.state.isSubMenu==v ? '' : v
    })
  }

  render() {
    const { isExpand, isSubMenu }= this.state
    const { module, screenWidth,  screenHeight, mini }= this.props
    
    if (mini) {
      return(
        <></>
      )
    } else {
      return (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: screenHeight, height: '100%'}}>
          <div style={{flex: '1'}}>
            <Popup position= 'right center' inverted content='Real Time Dashboard'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'dashboard', 'wasap')}>
                  <Icon name='chart area' color={this.props.mode=='dashboard' ? 'red' : 'black'} style={{fontSize: '1.15em'}}/>
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='2 Way Conversation'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'interaction', 'wasap')}>
                  <Icon name='comment alternate' color={this.props.mode=='interaction' ? 'red' : 'black'} />
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='Broadcast & Blast'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'broadcast', 'wasap')}>
                  <Icon name='shipping fast' color={this.props.mode=='broadcast' ? 'red' : 'black'} />
                </Button>
              } 
            />
            <Divider />
            <Popup position= 'right center' inverted content='Chat & Conversation'
              trigger= {
                <Button circular icon onClick={this.props.openWindow.bind(this, 'conversation', 'wasap')}>
                  <Icon name='sticky note' color={this.props.mode=='conversation' ? 'red' : 'black'} />
                </Button>
              } 
            />

            {this.props.groupId && this.props.groupId=='Client' && <>
              <Divider />
              <Button.Group icon color='pink'>
                <Button icon style={{marginLeft: '.3em'}}>
                  <Icon name='rocket' style={{fontSize: '1.3em'}} />
                </Button>
                <Dropdown className='button' icon='angle right' style={{borderRadius: '0 .3em .3em 0', padding: '.7em 0'}}>
                  {/* <div style={{width: '10em', height: '4em', background: '#000', position: 'absolute', left: '20px', top: '-1px'}} /> */}
                  <Dropdown.Menu style={{position: 'absolute',top: '-1px', left: 14, width: '15em', background: '#88bebe', padding: '3px 5px 3px 5px', textAlign: 'right', border: '0', borderRadius: '0 .3em .3em 0', borderLeft: '1px solid #e03997'}}>
                    <Button.Group>
                      <Popup position= 'top left' inverted content='Template / Script'
                        trigger= {
                            <Button icon onClick={this.props.openModal.bind(this, 'script', 'wasap')}>
                              <Icon name='file code' />
                            </Button>
                        } 
                      />
                    </Button.Group>
                    <span style={{padding: '.3em'}}></span>
                    <Button.Group>
                      <Popup position= 'top left' inverted content='User Management'
                        trigger= {
                            <Button icon onClick={this.props.openModal.bind(this, 'account', 'wasap')}>
                              <Icon name='user circle' />
                            </Button>
                        } 
                      />
                      <Popup position= 'top left' inverted content='Workgroup'
                        trigger= {
                            <Button icon onClick={this.props.openModal.bind(this, 'workgroup', 'wasap')}>
                              <Icon name='object ungroup' />
                            </Button>
                        } 
                      />
                    </Button.Group>
                    <span style={{padding: '.3em'}}></span>
                    <Button.Group>
                      <Popup position= 'top left' inverted content='Audit Trail'
                        trigger= {
                            <Button icon onClick={this.props.openModal.bind(this, 'audittrail', 'wasap')}>
                              <Icon name='paw' />
                            </Button>
                        } 
                      />
                    </Button.Group>
                    <span style={{padding: '.3em'}}></span>
                    <Button.Group>
                      <Popup position= 'top left' inverted content='Reporting'
                        trigger= {
                            <Button icon onClick={this.props.openModal.bind(this, 'reporting', 'wasap')}>
                              <Icon name='file alternate' />
                            </Button>
                        } 
                      />
                    </Button.Group>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </>}

            {this.props.groupId && this.props.groupId=='Client' && <>
              <Divider />
              <Popup position= 'right center' inverted content='Contact Management'
                trigger= {
                  <Button circular icon onClick={this.props.openModal.bind(this, 'contact', 'wasap')}>
                    <Icon name='user plus' color={this.props.mode=='contact' ? 'red' : 'black'} />
                  </Button>
                } 
              />
              
              <Divider />
              <Popup position= 'right center' inverted content='Whatsapp Account'
                trigger= {
                  <Button circular icon onClick={this.props.openModal.bind(this, 'waserver', 'wasap')}>
                    <Icon name='tablet' color={this.props.mode=='waserver' ? 'red' : 'black'} />
                  </Button>
                } 
              />
            </>}

            {this.props.groupId && this.props.groupId=='Supervisor' && <>
              <Divider />
              <Popup position= 'right center' inverted content='Workgroup'
                trigger= {
                  <Button circular icon onClick={this.props.openModal.bind(this, 'workgroup', 'wasap')}>
                    <Icon name='object ungroup' color={this.props.mode=='workgroup' ? 'red' : 'black'} />
                  </Button>
                } 
              />
              
              <Divider />
              <Popup position= 'right center' inverted content='Reporting'
                trigger= {
                  <Button circular icon onClick={this.props.openModal.bind(this, 'reporting', 'wasap')}>
                    <Icon name='file alternate' color={this.props.mode=='reporting' ? 'red' : 'black'} />
                  </Button>
                } 
              />
            </>}
          </div>
          <div style={{padding: '0 0'}}>
            
          </div>
        </div>
      );
    }
  }
}
