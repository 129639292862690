import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { SubmissionError, Field, reduxForm, change, formValueSelector, submit, reset } from 'redux-form'

import { connect } from "react-redux"
import { inboundMessaging, statusMessaging } from "../../actions/stream/messagingAction"
import { socketStatus, socketWhatsappStatistic, socketRequest, socketTransmit, socketWhatsappConnection } from "../../actions/socket/socketAction"
import { newchatWacommand } from "../../actions/whatsapp/wacommandAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';
import ChatboxContactForm from './ChatboxContactForm'
import ChatboxVisitorForm from './ChatboxVisitorForm'
import ChatboxMessagingBrowse from './ChatboxMessagingBrowse'
import ChatboxTargetForm from './ChatboxTargetForm'
import ChatboxCustomerForm from './ChatboxCustomerForm'


//CONNECT REDUX STORE
const selector = formValueSelector('ChatboxConversation')

const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
    messagingStore: state.messagingStore,
    wacommandStore: state.wacommandStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    inboundMessaging:(objParam) => dispatch(inboundMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),

    newchatWacommand:(objParam) => dispatch(newchatWacommand(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
    socketWhatsappConnection:(objParam) => dispatch(socketWhatsappConnection(objParam)),

    socketRequest:(objParam) => dispatch(socketRequest(objParam)),
    socketTransmit:(objParam) => dispatch(socketTransmit(objParam)),
  }
}

function submitText(values, e, props) {
  // const { mode } = this.props
  // console.log('!!! SUBMIT: ', values)
  // console.log('!!! SUBMIT: ', e)
  // console.log('!!! SUBMIT: ', props)
  // messageOmniwidget({...values})
  // props.messageOmniwidget({...values})
}

class ChatboxConversation extends Component {
  constructor(props) {
    super(props)
    this.state= {
      chats: [],

      expand: true,
      chatLength: 0, 
      lastSent: null, 
      currSent: null,

      recent: null,
      message: null,
    }
  }

  componentDidMount() {
    // console.log('DID MOUNT OUTBOUND MESSAGE', this.props.mode, this.props.params);

    this.props.params && this.setState({
      message: this.props.params,
      chats: this.props.params.conversations || [],
    })
  }

  componentDidUpdate(prevProps) {
    const { chatLength, lastSent, currSent, chats }= this.state
    const {inboundMessage, outboundMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})
    
    const streamMessage= this.props.mode=='inbound' ? inboundMessage : outboundMessage;
    
    streamMessage && streamMessage.messages && streamMessage.messages.map(item=>{
      if (
        this.state.recent!=streamMessage.recent && 
        this.props.params.messagingId==item.messagingId 
        // && (Array.isArray(item.conversations) && this.state.chats.length<item.conversations.length)
      ) {
        // console.log('DID UPDATE OUTBOUND MESSAGE', item);
        this.setState({
          recent: streamMessage.recent,
          message: item,
          chats: item.conversations || [],
        })
      }
    })

    if (lastSent!=currSent) {
      this.setState({
        lastSent: currSent,
      })
      this.props.dispatch(change('ChatboxConversation', 'message', ''))
    }

    if (chats.length>chatLength) {
      this.setState({
        chatLength: chats.length
      })
      
      var objDiv = document.getElementById("wd_chat");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  expand(v) {
    this.setState({
      expand: v!=undefined ? v : !this.state.expand
    })
  }

  setMode(mode, id) {
    this.setState({mode: mode, messagingId: id})
  }

  textInput(e, v) {
    // console.log(e)
    // console.log(v.value)
    if (v.value[v.value.length-1]=='\n') {
      if (v.value.length>1) {
        // console.log('SEND', v.value)
        this.props.dispatch(submit('ChatboxConversation'))
        // this.textSend(v.value.substr(0, v.value.length-1))
        const chats= this.state.chats;
        chats.push({
          // stamp_: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), //'2019-01-01 20:00',
          stamp: new Date().getTime(),
          sender: 'agent',
          message: v.value,

          // messageId: '-',
          // targetId: 0,
          // visitorId: 0,
          // originating: '-',
          // terminating: '-',
        })

        this.setState({
          chats: chats,
        })
        
        // this.props.socketTransmit({
        this.props.socketRequest({
          event: 'send://whatsapp:outmessage', 
          payload: {
            messagingId: this.props.params.messagingId || this.state.messagingId,
            chat: {
              // stamp_: new Date(),
              stamp: new Date().getTime(),
              sender: 'agent',
              message: v.value,
            },
            data: {
              contactId: this.props.params && this.props.params.contactId,
              visitorId: this.props.params && this.props.params.visitor && this.props.params.visitor.visitorId,
              targetId: this.props.params && this.props.params.target && this.props.params.target.targetId,
              customerId: this.props.params && this.props.params.customer && this.props.params.customer.customerId,
              
              messagingId: this.props.params && this.props.params.messagingId || this.state.messagingId,
              flow: 1,
              channelType: 'WA',
            }

            // targetId: 0,
            // visitorId: 0,
            // customerId: 0,
            // originating: '-',
            // terminating: '-',
          }, 
          socket: this.context.socket,
        })
        .then(response=>{
          // console.log('SOCKET REQUEST RESPONSE', response)
          if (response && response.value && response.value.data && response.value.data.messagingId) {
            this.setState({
              messagingId: response.value.data.messagingId,
            })
          }
        })
        .catch(error=>{
          console.log('SOCKET REQUEST ERROR', error)
        });

        // this.props.newchatWacommand({});
        // .then(response=>{
        //   console.log('SOCKET REQUEST RESPONSE', response)
        // })
        // .catch(error=>{
        //   console.log('SOCKET REQUEST ERROR', error)
        // });

        this.setState({
          currSent: new Date()
        })
      }
      // this.props.dispatch(change('ChatboxConversation', 'message', ''))
    }
  }

  sendButton() {
    // if (this.props.message.length>=1) {
    //   this.props.dispatch(submit('ChatboxConversation'))
    //   this.textSend(this.props.message.substr(0, this.props.message[this.props.message.length-1]=='\n' ? this.props.message.length-1 : this.props.message.length))
    //   this.setState({
    //     currSent: new Date()
    //   })
    // }
  }

  itemClick(e, v) {
    // console.log(v)
    // this.props.dispatch(change('ChatboxConversation', 'message', (this.props.message || '') + (this.props.message ? '\n\n' : '') + (v.text || '') ));
  }

  onSubmit(values) {
    // const { mode } = this.props
    console.log('!!! SUBMIT: ', values)
    // this.props.messageOmniwidget({...values})
  }

  textSend(message) {
    // // console.log('!!! TEXT SEND', message)
    // this.props.textSend(message)
  }

  variableSend(message) {
    // this.props.textSend(message, 'variable')
  }

  commandSend(message) {
    // this.props.textSend(message, 'command')
  }

  closeChat() {
    // const { conversation, chats } = this.props
    // this.props.endConversation('Chat', 
    //   {
    //     command: 'end_by_agent',
    //     visitorId: conversation.visitorId,
    //     agentId: conversation.userId,
    //     clientId: conversation.clientId,

    //     conversations: chats,
    //   }
    // )
  }

  render() {
    //console.log(this.props.messagingStore.inboundMessaging)
    const { expand, content, recent, message, chats }= this.state;
    const { screenWidth, screenHeight, phoneState, visitor, conversation, status, handleSubmit, pristine, reset, submitting, mode, dispatch } = this.props
    const waConnnections= (this.props.socketStore.socketWhatsappConnection!==undefined ? this.props.socketStore.socketWhatsappConnection.data.payload.data : undefined)
    
    const onlines= [], offlines= [];
    waConnnections && waConnnections.provider.mobiles && waConnnections.provider.mobiles.map(item=>{
      if (item.online=='1') {
        onlines.push(item.mobile)
      } else {
        offlines.push(item.mobile)
      }
    })

    return (
      <div>
        <div style={{display: 'flex'}}>
          <div style={{width: 350}}>
            <div style={{display: 'flex'}}>
              <div style={{height: '4em', width: '4em', background: '#fafafa', textAlign: 'center', cursor: 'pointer', borderRadius: '.3em', overflow: 'hidden'}} onClick={this.expand.bind(this, !this.state.expand)}>
                <Image src={mode=='inbound' ? 'https://www.kindpng.com/picc/m/576-5768791_avatar-png-image-with-transparent-background-ninja-avatar.png' : 'https://cdn-icons-png.flaticon.com/512/7070/7070249.png'} size='tiny' />
                {/* <Icon name='user circle' color='grey' style={{fontSize: '3em', margin: '0'}} /> */}
              </div>
              <div style={{flex: '1', padding: '.5em 1em'}}>
                <Header as='h4'>
                  <Header.Content>
                    <span style={{textTransform: 'capitalize'}}>
                      {this.props.params && ((this.props.params.name || (this.props.params.target && this.props.params.target.name) || (this.props.params.customer && this.props.params.customer.name) || (this.props.params.visitor && this.props.params.visitor.name) || (this.props.params.contact && this.props.params.contact.name) || (this.props.params.terminating && (this.props.params.terminating.substring(0,5).replace('62', '0') +'XXXX'+ this.props.params.terminating.substring(this.props.params.terminating.length-4,this.props.params.terminating.length))) || 'Guest / Visitor'))}
                    </span>
                    <Header.Subheader>{this.props.params && ((this.props.params.target && 'Campaign target contact') || (this.props.params.customer && 'Customer data') || (this.props.params.visitor && 'Public visitor') || (this.props.params.visitor && 'Saved Contacts') || 'Guest / Visitor')}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
              <div>
                <Button color={expand ? 'grey' : null} size='mini' icon onClick={this.expand.bind(this, true)}><Icon name='user' /></Button>
                <Divider style={{margin: '.1em 0'}} hidden />
                <Button color={!expand ? 'grey' : null} size='mini' icon onClick={this.expand.bind(this, false)}><Icon name='comment alternate' /></Button>
              </div>
            </div>
            <div style={{display: 'flex', marginTop: '.5em', padding: '0 0 0 0', flexDirection: 'column'}}>
              <div style={{flex: '1', padding: '0'}}>
                <div id='wd_chat' className='chat' style={{flex: '1', padding: '.1em 0', overflowY: 'auto', height: screenHeight-350, border: '1px solid #ccc', borderRadius: '.3em'}}>
                  <div style={{padding: '.2em 0em', minHeight: '100%', display: 'flex', flexDirection: 'column', background: '#fff'}}>
                    <div style={{flex: '1'}}>
                    </div>
                    {
                      chats && chats.map((item, idx)=>{
                        return(
                          <div key={idx} style={{margin: '.1em .3em', textAlign: item.sender=='cust' ? 'left' : 'right'}}>
                            <div style={{background: item.sender=='cust' ? '#ffebe9' : '#d7fffe', float: item.sender=='cust' ? 'left' : 'right', padding: item.sender=='cust' ? '.5em 1em .2em .5em' : '.5em .5em .2em 1em', margin: item.sender=='cust' ? '0 2em 0 0' : '0 0 0 2em', borderRadius: item.sender=='cust' ? '1em 2.5em 2.5em 0em' : '2.5em 1em 0em 2.5em'}}>
                              <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                                <Header.Content style={{color: '#111', fontSize: '11pt', overflowWrap: 'anywhere'}}>
                                  <Header.Subheader style={{fontSize: '.8em', borderBottom: '1px solid rgba(100, 100, 100, .5)'}}> ({idx+1}) {item.sender=='fadfa agent' ? this.props.params.target.name || 'Guest' : ''} <b style={{color: '#900'}}>{item.stamp && format(new Date(item.stamp), 'HH:mm')}</b>{item.stamp && format(new Date(item.stamp), ', dd/MMM')}</Header.Subheader>
                                  {item.message}
                                  {item.sender=='agent' &&
                                  <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}>
                                    <Icon 
                                    name={(message.status<0 && 'times') || (message.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'eye') || (message.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (message.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || 'ellipsis horizontal'} 
                                    color={(message.status<0 && 'red') || (message.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'brown') || (message.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'blue') || (message.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'grey') || 'black'}
                                    style={{margin: '0', padding: '0'}}/>
                                    </Header.Subheader>}
                                </Header.Content>
                              </Header>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div style={{padding: '1em 0 0 0', borderTop: '1px solid #fff'}}>
                <Form onSubmit={handleSubmit}>
                  <div style={{display: 'flex'}}>
                    <div style={{flex: '1'}}>
                      {/* <Button icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='angle down' style={{fontSize: '1.1em'}}/> Questions template here
                      </Button> */}
                      {/* {questions && questions.length>0 && 
                        <Dropdown text='Write or pick your questions' icon='angle down'>
                          <Dropdown.Menu>
                            <Dropdown.Menu scrolling>
                              {questions.map((option) => (
                                <Dropdown.Item key={option.value} {...option}  onClick={this.itemClick.bind(this)}/>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown.Menu>
                        </Dropdown>
                      } */}
                    </div>
                    {/* <div>
                      <Button type='button' icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='phone' style={{fontSize: '1.1em', transform: 'rotate(20deg)'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button type='button' icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='file alternate outline' style={{fontSize: '1.1em'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button type='button' icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='image outline' style={{fontSize: '1.3em'}}/>
                      </Button>
                    </div> */}
                    {/* <div>
                      <Button type='button' icon style={{border: 'none', background: 'none', padding: '.3em'}}>
                        <Icon name='camera retro' style={{fontSize: '1.2em'}}/>
                      </Button>
                    </div> */}
                  </div>
                  <Form.Group widths='16'>
                    <Field name='message' component={TextAreaField} style={{border: '1px solid #bbb'}} 
                    onInput={this.textInput.bind(this)} 
                    // disabled={this.props.phoneState=='disconnected' || status!='occupied'}
                    width={16}
                    rows={3}
                    maxLength={150}
                    placeholder='Type message here ... <enter>' />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          {this.state.expand &&
            <div style={{flex: '1', marginLeft: '1.5em', background: '#f0f0f0'}}>
              {
                (this.props.params && this.props.params.targetId && this.props.params.target &&<ChatboxTargetForm conversation={this.props.params} closeModal={this.props.closeModal} />) || 
                (this.props.params && this.props.params.customerId && this.props.params.customer &&<ChatboxCustomerForm conversation={this.props.params} closeModal={this.props.closeModal} />) || 
                (this.props.params && this.props.params.contactId && this.props.params.contact && <ChatboxContactForm conversation={this.props.params} closeModal={this.props.closeModal} />) ||
                (this.props.params && <ChatboxVisitorForm conversation={this.props.params} closeModal={this.props.closeModal} />)
              }
            </div>
          }
          {!this.state.expand && 
            <div style={{flex: '1', marginLeft: '1.5em', background: '#f3f3f3'}}>
              <ChatboxMessagingBrowse params={this.props.params} campaignId={this.props.params && this.props.params.campaignId} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} />
            </div>
          }
        </div>
        {onlines.length<=0 && 
          <div style={{background: '#777777aa', position: 'fixed', height: '100%', width: '100%', padding: '5em', top: 0, left: 0}}>
            <div style={{margin: '30% auto', textAlign: 'center', background: '#a10', maxWidth: 350, border:'1px solid #666', padding: '3em 2em', borderRadius: '1em'}}>
              <Header as='h2' inverted icon>
                <Icon name='warning sign' style={{fontSize: '1.5em'}}/>
                <Header.Content>
                  <b>NO ACCOUNT</b>
                  <Divider />
                  <Header.Subheader style={{fontSize: '.65em', margin: '.5em 0'}}>
                    Please, <br />start  Whatsapp Account connection service, to initiate two ways interactive conversations.
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Divider style={{margin: '1em 0 3em 0'}}/>
              <Button color='yellow' icon size='big' style={{color: '#222'}} onClick={this.props.closeModal.bind(this)}><Icon name='times' style={{color: '#000', fontSize: '1.2em'}} /> CLOSE</Button>
            </div>
          </div>
        }
      </div>
    )
  }
}

ChatboxConversation.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: {}, //state.omniwidgetStore && state.omniwidgetStore.messageOmniwidget!=undefined ? state.omniwidgetStore.messageOmniwidget.data : {},
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:false,

      message: selector(state, 'message'),
    }),
  )
  (
    reduxForm({
      form: 'ChatboxConversation',	// a unique identifier for this form
      onSubmit: submitText,
    }) (ChatboxConversation)
  )
)