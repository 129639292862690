import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { targetStatus } from "../../libraries/common/StaticMasterData"
import ModalContainer from '../../libraries/common/ModalContainer';
import TargetRemark from '../../libraries/agent/TargetRemark';


import { connect } from "react-redux"
import { callOmniphone, predictiveOmniphone } from "../../actions/stream/omniphoneAction"
import { browseTarget, statusTarget } from "../../actions/stream/targetAction"
import { socketAgentStatus } from "../../actions/socket/socketAction"

import WebSocketProvider, { WebSocketContext } from '../../actions/socket/Websocket';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,

    omniphoneStore: state.omniphoneStore,
    targetStore: state.targetStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketAgentStatus:(objParam) => dispatch(socketAgentStatus(objParam)),

    callOmniphone:(objParam) => dispatch(callOmniphone(objParam)),
    predictiveOmniphone:(objParam) => dispatch(predictiveOmniphone(objParam)),

    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
    statusTarget:(objParam) => dispatch(statusTarget(objParam)),
  }
};


class TargetBrowse extends Component {
  constructor(props) {
    super(props);
    this.timers= []
    this.state= {
      calling: 0,
      color: 'teal',
      targetId: null,
      remark: true,
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget({search: search, paging: paging})
  }

  componentDidUpdate() {
    const { code, data }= (this.props.omniphoneStore.callOmniphone!==undefined ? this.props.omniphoneStore.callOmniphone : {})
    if (data && data.targetId!=this.state.targetId && this.state.targetId!=null) {
      this.setState({
        targetId: data.targetId,
        // calling: (code=='00' && '3') || (code=='01' && '3') || this.state.calling
        calling: this.state.calling
      })
    }
  }

  componentWillUnmount() {
    this.timers.map(timer=>{
      clearTimeout(timer);
    })
  }

  callOmniphone(predialType, targetId, campaignId, target) {
    this.setState({
      calling: 5,
      color: 'teal',
      targetId: 0,
      target: target,
    })
    this.props.callOmniphone({predialType: predialType, targetId: targetId, campaignId: campaignId})
    this.callingCounter()

    //set next 60 minute to predictive mode
    const parent= this;
    const timer= setTimeout(()=>{
      parent.props.predictiveOmniphone()
    }, 60000)

    this.timers.push(timer);
  }

  onChangeStatus(id, status) {
    this.props.statusTarget({targetId:id, isEnabled: status})
  }

  callingCounter() {
    const parent= this;
    const timer= setInterval(()=>{
      parent.setState({
        calling: parent.state.calling - 1,
        color: parent.state.calling%2==0 ? 'orange' : 'teal',
      })
      if (parent.state.calling<1) {
        clearInterval(timer);
      }
    }, 1000)
  }

  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  closeModal() {
    this.setState({
      remarkModal: false,
      remarkItem: null,
    })
    // this.props.closeModal();
  }

  showRemark(item) {
    this.setState({
      remarkModal: true,
      remarkItem: item,
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { calling, color, remark }= this.state

    const { data, search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const callResult= (this.props.omniphoneStore.callOmniphone!==undefined ? this.props.omniphoneStore.callOmniphone : null)
    const agentStatus= (this.props.socketStore.socketAgentStatus!==undefined ? this.props.socketStore.socketAgentStatus.data : undefined)
    const { dialings, status }= (agentStatus && agentStatus.payload.data) || []
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column'}}>
        {/* <ModalContainer size='small' loader={
          <div style={{textAlign: 'center'}}>
            <Header as='h3' color='yellow' inverted style={{display: 'inline-block', textAlign: 'left', padding: '1em 1em', background: callResult && callResult.code!='00' ? '#b21' : '#333', border: '1px dashed #888'}}>
              <Icon name='circle notch' loading style={{fontSize: '2em'}} color={color}/>
              <Header.Content>
                Dialing - {this.state.target && this.state.target.name && this.state.target.name.split(' ')[0]} ... ({calling})
                <Header.Subheader style={{margin: '.5em 0'}}>Sending dialing command has result: {callResult && callResult.code=='00' && 'OK'} {callResult && callResult.code!='00' && 'NOK'} {(callResult && callResult.data && this.state.targetId==callResult.data.targetId && callResult.text) || 'On Progress'}</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          } open={calling>0}/> */}

        <div style={{flex: '1'}}>
          <Table unstackable selectable columns='16' celled striped basic='very' style={{border: '1px dashed #ddd'}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='5'>
                  <Header as='h5' color='blue'>TARGET</Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>CUST REF.</Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell width='4'>
                  <Header as='h5' color='blue'>CONTACT</Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell width={7} style={{background: remark ? '#e7feff' : '#fff2f2'}}>
                  <Header as='h5' color='blue'><Icon name={remark ? 'toggle on' : 'toggle off'} color={remark ? 'blue' : 'red'} style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setRemark.bind(this)}/><Header.Content>{remark ? 'REMARK' : 'RESULT'}</Header.Content></Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>ATTEMPTS</Header>
                </Table.HeaderCell>
                <Table.HeaderCell>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!=undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell>
                        <Icon circular inverted color='yellow' style={{fontSize: '1.2em', margin: '0', cursor: 'pointer'}} name='file alternate outline' onClick={this.showRemark.bind(this, item)}/>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Icon 
                          color={(item.isEnabled=='0' && 'grey') || (item.status<'0' && 'red') || (item.isAchieved && 'blue') || (item.isDelivered && 'teal') || (item.isPrepared && 'yellow') || 'grey'} 
                          name='circle thin' 
                          style={{fontSize: '1.1em'}} />
                          <Header.Content>
                            {item.name || 'No Name!'} <b>{(item.isEnabled=='0' && <i>(Disabled)</i>) || ''}</b>
                            {/* {item.status} / {item.isEnabled} */}
                            <Header.Subheader>
                              <b>{((item.agentId && 'Locked') || (item.tagger ?  'Locked: '+ item.tagger : 'Floating')) + (item.agent ? ' / '+ ((item.agent && item.agent.name) || '-') : '')}</b> {' '}
                            
                              {item.campaign && <> / <span style={{textDecoration: item.campaign.isEnabled=='0' || item.campaign.isExpired!='0' ? 'line-through' : 'none'}}>{item.campaign && item.campaign.name && item.campaign.name.length>20 ? item.campaign.name.substr(0,20) +' ...' : item.campaign.name}</span> 
                                {(item.campaign.isEnabled=='0' && <i> (Disabled)</i>) || (item.campaign.isExpired=='-1' && <i> (Upcoming)</i>) || (item.campaign.isExpired=='1' && <i> (Expired)</i>)}
                              </>}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Header as='h5'>
                          {item.campaign && item.campaign.channels &&
                            <Header.Content>
                              {(item.campaign.channels[0]=='Voice' || item.campaign.channels[0]=='IVR') && (item.mobile || item.homePhone || item.officePhone || 'No phone')}
                              {item.campaign.channels[0]=='SMS' && (item.email || 'No mobile')}
                              {item.campaign.channels[0]=='Email' && (item.email || 'No email')}
                              {item.campaign.channels[0]=='Whatsapp' && (item.whatsapp || 'No whatsapp')}
                              {item.campaign.channels[0]=='Telegram' && (item.whatsapp || 'No telegram')}
                              {item.campaign.channels[0]=='Twitter' && (item.whatsapp || 'No twitter')}
                              {item.campaign.channels[0]=='Facebook' && (item.whatsapp || 'No facebook')}
                              {item.campaign.channels[0]=='Instagram' && (item.whatsapp || 'No instagram')}
                              <Header.Subheader style={{whiteSpace: 'nowrap'}}>
                                {item.campaign.channels[1] && <>
                                  {(item.campaign.channels[1]=='Voice' || item.campaign.channels[1]=='IVR') && (item.mobile || item.homePhone || item.officePhone || 'No phone')}
                                  {item.campaign.channels[1]=='SMS' && (item.email || 'No mobile')}
                                  {item.campaign.channels[1]=='Email' && (item.email || 'No email')}
                                  {item.campaign.channels[1]=='Whatsapp' && (item.whatsapp || 'No whatsapp')}
                                  {item.campaign.channels[1]=='Telegram' && (item.whatsapp || 'No telegram')}
                                  {item.campaign.channels[1]=='Twitter' && (item.whatsapp || 'No twitter')}
                                  {item.campaign.channels[1]=='Facebook' && (item.whatsapp || 'No facebook')}
                                  {item.campaign.channels[1]=='Instagram' && (item.whatsapp || 'No instagram')}
                                </>}
                                {!item.campaign.channels[1] && 'Single channel campaign only'}
                              </Header.Subheader>
                            </Header.Content>
                          }
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            {item.custRef}
                            <Header.Subheader>{item.contractRef}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {remark && 
                      <Table.Cell style={{background: '#e7feff'}}>
                        <Header as='h5' style={{marginBottom: '0'}}>
                          <div style={{background: (item.status=='1' && '#84dbff') || (item.status=='0' && '#ffb3b3') || '#ddd', textAlign: 'right', display: 'inline-block', width: '4em', padding: '.5em', marginRight: '.5em'}}>{item.progress} %</div>
                          <Header.Content>
                            {item.remark || 'No remark !'}
                            {/* <Header.Subheader>Exp. on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'none'}</i></Header.Subheader> */}
                            {item.remark=='Rescheduled' && <Header.Subheader>{item.remarks.appointmentSession}, {item.remarks.appointmentDate!=null && format(parse(item.remarks.appointmentDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Promised' && <Header.Subheader>{item.remarks.promisedDate!=null && format(parse(item.remarks.promisedDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark=='Paid' && <Header.Subheader>{item.remarks.paidDate!=null && format(parse(item.remarks.paidDate.substr(0,10), 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy')}</Header.Subheader>}
                            {item.remark!='Rescheduled' && item.remark!='Promised' && item.remark!='Paid' && <Header.Subheader>Exp. on <i>{item.expired!=null ? format(parse(item.expired, 'yyyy-MM-dd', new Date()), 'dd / MMM yyyy') : 'none'}</i></Header.Subheader>}
                            {item.remarks && <Header.Subheader style={{paddingTop: '.5em'}}>{item.remarks.reason || 'No information'}</Header.Subheader>}
                          </Header.Content>
                        </Header>
                        {/* <div style={{fontSize: '.9em'}}>{item.remark ? item.reason : 'No additional information for certain remark'}</div> */}
                      </Table.Cell>}
                      {!remark && 
                      <Table.Cell style={{background: '#fff2f2'}}>
                        <Header as='h5'>
                          <Icon name={(item.dropStamp && 'exclamation circle') || (item.status<0 && 'times circle outline') || (item.status>0 && 'check circle') || 'circle outline'} color={(item.dropStamp && 'orange') || (item.status<0 && 'red') || (item.status>0 && 'blue') || 'teal'} style={{fontSize: '1.25em'}}/> 
                          <Header.Content>
                            {(item.dropStamp && ' Dropped') || targetStatus[item.status] || 'No result'}
                            <Header.Subheader>{item.reason || 'No info'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>}
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <b>{item.isEnabled=='0' && <i>Disabled</i> || (item.totAttempt>=item.maxAttempt && <i>Exceeded</i>) || 'Available'}</b>
                            <Header.Subheader>{item.totAttempt} of {item.maxAttempt}</Header.Subheader>
                            {/* {item.totAttempt} / {item.maxAttempt}
                            <Header.Subheader><b><i>{item.isEnabled=='0' && 'Disabled' || (item.totAttempt>=item.maxAttempt && 'Exceeded') || 'Ongoing'}</i></b></Header.Subheader> */}
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {(dialings && dialings.indexOf(item.targetId)>=0) &&
                          <Popup header='Ringing' content='Dialing on progress' trigger={<Label color='green' className='blink' style={{margin: '0', padding: '.5em .5em', whiteSpace: 'nowrap'}}><Icon color='red' name='phone' inverted circular style={{margin: '0', cursor: 'pointer'}}/> RINGING</Label>} />
                        }
                        {(!dialings || dialings.indexOf(item.targetId)<0) && (item.campaign && item.campaign.channels.indexOf('Voice')>=0) &&
                          <Dropdown pointing='top right' direction='left' icon='ellipsis vertical' inline>
                            <Dropdown.Menu>
                              {!item.predialCalls && <Dropdown.Item disabled= {(item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online'} onClick= {this.callOmniphone.bind(this, 'mobile', item.targetId, item.campaignId, item)}><Icon name='phone volume' color='blue'/>Call</Dropdown.Item>}
                              {item.predialCalls && item.predialCalls.map((predialType, i)=>{
                                return(
                                  <Popup content="Cost will be charged based on providers rate." inverted key={i}
                                    trigger={
                                      <Dropdown.Item key={i} disabled= {(item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online'} style={{color: ((item.campaign && item.campaign.channels && item.campaign.channels.indexOf('Voice')<0) || status!='online' ? '#333' : '#1b5cab')}} onClick= {this.callOmniphone.bind(this, predialType, item.targetId, item.campaignId, item)}><Icon name={(predialType=='Mobile' && 'mobile alternate') || (predialType=='Office Phone' && 'fax') || (predialType=='Home Phone' && 'phone') || (predialType=='Emergency Phone' && 'warning sign') || (predialType=='Other/Relative Phone' && 'home') || 'phone volume'} />Call {predialType || 'Phone'}</Dropdown.Item>
                                    } position='left center'
                                  />
                                )
                              })}
                              {/* <Divider style={{margin: '0'}}/>
                              <Dropdown.Item disabled ><Icon name='comment alternate outline' color='blue'/>Messaging</Dropdown.Item>
                              <Divider style={{margin: '0'}}/>
                              <Dropdown.Item disabled ><Icon name='share alternate' color='blue'/>Social Media</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
            {/* <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='6' style={{fontSize: '1em'}}>
                  <span style={{marginRight: '1em'}}><Icon name='window close outline' color='grey' style={{fontSize: '1.2em'}}/> Undelivered</span>
                  <span style={{marginRight: '1em'}}><Icon name='check square outline' color='blue' style={{fontSize: '1.2em'}}/> Delivered</span>
                  <span style={{marginRight: '1em'}}><Icon name='check square' color='blue' style={{fontSize: '1.2em'}}/> Achieved</span>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer> */}
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }

        {this.state.remarkModal && this.state.remarkItem && <ModalContainer size='small' content={
          <TargetRemark data={this.state.remarkItem} state={this.state}/>
        } closeModal={this.closeModal.bind(this)} /> }
      </div>
    );
  }
}

TargetBrowse.contextType= WebSocketContext;

export default connect(mapStateToProps, mapDispatchToProps)(TargetBrowse)
