import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { targetStatus } from "../../libraries/common/StaticMasterData"

import { connect } from "react-redux"
import { browseTarget, statusTarget, requeueTarget, removeTarget } from "../../actions/stream/targetAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseTarget:(objParam) => dispatch(browseTarget(objParam)),
    statusTarget:(objParam) => dispatch(statusTarget(objParam)),
    requeueTarget:(objParam) => dispatch(requeueTarget(objParam)),
    removeTarget:(objParam) => dispatch(removeTarget(objParam)),
  }
};


class TargetBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {
      remark: false,
    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.browseTarget(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    this.props.campaignId && this.props.browseTarget({search: {...(this.props.campaignId ? {campaignId: this.props.campaignId} : {})}})
    !this.props.campaignId && this.props.browseTarget({search: {...search, ...(this.props.campaignId ? {campaignId: this.props.campaignId} : {campaignId: undefined})}, paging: paging})
  }

  onChangeStatus(id, status) {
    this.props.statusTarget({targetId:id, isEnabled: status})
  }

  requeueTarget(id) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})

    this.props.requeueTarget({targetId:id})
    setTimeout(()=>{
      this.props.browseTarget(
      {
        paging: {...paging},
        search: search
      }
    )}, 1000);
  }

  removeTarget(id) {
    const { search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})

    this.props.removeTarget({targetId:id})
    setTimeout(()=>{
      this.props.browseTarget(
      {
        paging: {...paging},
        search: search
      }
    )}, 1000);
  }

  setRemark() {
    const { remark }= this.state
    this.setState({
      remark: !remark
    })
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { remark }= this.state

    const { data, search, paging }= (this.props.targetStore.browseTarget!==undefined ? this.props.targetStore.browseTarget : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    const scripts= data && data[0] && data[0].campaign && data[0].campaign.scriptExt && !Array.isArray(data[0].campaign.scriptExt) && data[0].campaign.scriptExt.split(/({[a-zA-Z]*})/)
    const vars= new Map()
    scripts && scripts.map(item=>{
        item.match(/{[a-zA-Z]*}/) && !vars.get(item) && vars.set(item)
    })            
    
    console.log(data && data[0] && data[0].campaign && data[0].campaign.scriptExt)
    console.log(scripts)
    console.log([...vars.keys()])
    vars.delete('{custName}');

    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', overflow: 'auto'}}>
          <div>
            <Table unstackable selectable columns='16' celled striped basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell width={!vars ? 16 : Math.round(16/([...vars.keys()].length + 1))} style={{minWidth: '15em'}}><Header color='blue' as='h5'>WHATSAPP</Header></Table.HeaderCell>
                  {vars && [...vars.keys()].map((item, idx)=>{
                    return(
                      <Table.HeaderCell style={{minWidth: '10em'}} key={idx} width={Math.round(16/[...vars.keys()].length)}><Header color='blue' as='h5'>{item.replace(/{|}/gm,'').toUpperCase()}</Header></Table.HeaderCell>
                    )
                  })}
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { data && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
                { data ? data.map((item,key)=>{
                    return(
                      <Table.Row key={++seq}>
                        <Table.Cell>{seq}</Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                            <Popup inverted content='Delete' trigger={
                              <Icon name='times' color='red' onClick={this.removeTarget.bind(this, item.targetId)} style={{cursor: 'pointer', fontSize: '1.2em'}}/>
                            } />
                        </Table.Cell>
                        <Table.Cell textAlign='center' style={{whiteSpace: 'nowrap', paddingTop: '1em'}}>
                            <Popup inverted content='Edit' trigger={
                              <Icon name='edit' onClick={this.props.setMode.bind(this, '2',item.targetId)} style={{cursor: 'pointer'}} />
                            } />
                            {/* <Popup inverted content='Requeue' trigger={
                              <Button icon onClick={this.requeueTarget.bind(this, item.targetId)}><Icon name='database' color='pink' /></Button>
                            } /> */}
                        </Table.Cell>

                        {/* <Table.Cell>
                          <Header as='h5'>
                            <Icon 
                            color={(item.isEnabled=='0' && 'grey') || (item.status<0 && 'red') || (item.isAchieved && 'blue') || (item.isDelivered && 'teal') || (item.isPrepared && 'yellow') || 'green'} 
                            name='circle thin' 
                            style={{fontSize: '1.1em'}} />
                            <Header.Content>
                              {item.name || 'No Name!'}
                              {item.campaign!=null && <Header.Subheader><span style={{textDecoration: item.campaign.isEnabled=='0' || item.campaign.isExpired!='0' ? 'line-through' : 'none'}}>{item.campaign && item.campaign.name && item.campaign.name.length>20 ? item.campaign.name.substr(0,20) +' ...' : item.campaign.name}</span> 
                              {(item.campaign.isEnabled=='0' && <i> (Disabled)</i>) || (item.campaign.isExpired=='-1' && <i> (Upcoming)</i>) || (item.campaign.isExpired=='1' && <i> (Expired)</i>)}</Header.Subheader>}
                            </Header.Content>
                          </Header>
                        </Table.Cell> */}
                        <Table.Cell>
                          <Header as='h5'>
                            {item.campaign && item.campaign.channels &&
                              <Header.Content>
                                <b style={item.isEnabled=='1' ? {} : {color: '#666', textDecoration: 'line-through'}}>{item.name || item.whatsapp || 'No Info'}</b>
                                <Header.Subheader>{item.totAttempt} / {item.maxAttempt} Attempts</Header.Subheader>
                              </Header.Content>
                            }
                          </Header>
                        </Table.Cell>
                        {vars && [...vars.keys()].map((itemvar, idx)=>{
                          const field= itemvar.replace(/{|}/gm,'');
                          return(
                            <Table.Cell key={idx} width={Math.round(16/[...vars.keys()].length)}>
                              <Header as='h5'>
                                {item[field=='custName' ? 'name' : field]}
                              </Header>
                            </Table.Cell>
                          )
                        })}
                        <Table.Cell>
                          <Icon style={{fontSize: '1.2em'}} name={(item.whatsappResult=='1' && 'check square outline') || (item.whatsappResult=='-1' && 'times rectangle') || 'square outline'} />
                        </Table.Cell>
                      </Table.Row>
                    )
                  }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
                }
              </Table.Body>
              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='8' style={{fontSize: '1em'}}>
                    <span style={{marginRight: '1em'}}><Icon name='window close outline' color='grey' style={{fontSize: '1.2em'}}/> Undelivered</span>
                    <span style={{marginRight: '1em'}}><Icon name='check square outline' color='blue' style={{fontSize: '1.2em'}}/> Delivered</span>
                    <span style={{marginRight: '1em'}}><Icon name='check square' color='blue' style={{fontSize: '1.2em'}}/> Achieved</span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          </div>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetBrowse)
