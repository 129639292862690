import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Popup,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { browseAccount, statusAccount } from "../../../actions/halo/accountAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    accountStore: state.accountStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseAccount:(objParam) => dispatch(browseAccount(objParam)),
    statusAccount:(objParam) => dispatch(statusAccount(objParam)),
  }
}
class AccountBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.accountStore.browseAccount!==undefined ? this.props.accountStore.browseAccount : {})
    this.props.browseAccount(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.accountStore.browseAccount!==undefined ? this.props.accountStore.browseAccount : {})
    this.props.browseAccount({search: search, paging: paging})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusAccount({userId:id, isEnabled: status})
  }

  render() {
    //console.log(this.props.accountStore.browseAccount)
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.accountStore.browseAccount!==undefined ? this.props.accountStore.browseAccount : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
        <div style={{flex: '1', padding: '0'}}>
          <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell width='8'>
                  <Header as='h5' color='blue'>
                    ACCOUNT
                  </Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    CONTACT
                  </Header>
                </Table.HeaderCell> */}
                <Table.HeaderCell>
                  <Header as='h5' color='blue'>
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='6'>
                  <Header as='h5' color='blue'>
                    ACTIVE PERIOD
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='2'>
                  <Header as='h5' color='blue'>
                    TAGGER
                  </Header>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      <Table.Cell textAlign='center'>
                        <Popup header='Edit' trigger={<Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.userId)}/>} />
                      </Table.Cell>
                      <Table.Cell>
                        <Header color={((item.isEnabled=='0' || item.isDeleted=='1') && 'brown') || 'black'} as='h5'>
                          {item.avatar && <Image src={item.avatar} rounded size='mini' />}
                          {(item.isDeleted=='1' || !item.avatar) && <Icon name='user circle' color={(item.isEnabled=='1' && 'teal') || (item.isEnabled=='0' && 'grey') || (item.isDeleted=='1' || 'red')}/>}
                          <Header.Content>
                            <span style={{textDecoration: item.isEnabled=='0' || item.isDeleted=='1' ? 'line-through' : 'none'}}>{item.account}</span> 
                            <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isSuspended=='1' && <i> (Suspended)</i>) || (item.isDeleted=='1' && <i> (Deleted)</i>)}</b>
                            <Header.Subheader><b style={{color: '#c31'}}>{item.groupId}</b> / {item.name}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Header as='h6'>
                          <Header.Content>
                            <Icon color='grey' name='phone square' /> {item.mobile}<br />
                            <Icon color='grey' name='mail square' /> {item.email}
                          </Header.Content>
                        </Header>
                      </Table.Cell> */}
                      <Table.Cell textAlign='center'>
                        {/* <Form.Checkbox name='accountStatus_1' 
                        toggle
                        defaultChecked={item.isEnabled=='1' ? true : false}
                        onChange={this.onChangeStatus.bind(this, item.userId,(item.isEnabled=='1' ? '0' : '1'))}
                        placeholder='Account status' /> */}
                        <Icon name={item.isEnabled ? 'toggle on' : 'toggle off'} color={item.isEnabled ? 'teal' : 'grey'} style={{fontSize: '1.5em'}}/>
                      </Table.Cell>
                      <Table.Cell> 
                        <Header as='h5'>
                          <Header.Content>
                            {item.enabledStamp!=null ? format(parse(item.enabledStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yy') : ' ~ '} - <b><i>{item.disabledStamp!=null ? format(parse(item.disabledStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yy') : ' ~ '}</i></b>
                            <Header.Subheader>{(!item.disabledStamp && 'Subscription - Auto Renewal') || 'Subscription Period'}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Header as='h5'>
                          <Header.Content>
                            <Header.Subheader>{item.tagger}</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        {paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountBrowse)
