import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Select } from 'semantic-ui-react'

import DismissableMessage from '../common/DismissableMessage'
import { ticketOptions, progressOptions }  from '../common/StaticMasterData'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { FileUploadField, ImageUploadField, NumberField, InputField, CheckboxField, SelectField, TextAreaField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, userPassword, confirmedPassword, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { resetTicket, getTicket,updateTicket, createTicket } from "../../actions/service/ticketAction"
import { listSegment } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('TicketForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    ticketStore: state.ticketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listSegment:(objParam) => dispatch(listSegment(objParam)),

    resetTicket:(objParam) => dispatch(resetTicket(objParam)),
    getTicket:(objParam) => dispatch(getTicket(objParam)),
    createTicket:(objParam) => dispatch(createTicket(objParam)),
    updateTicket:(objParam) => dispatch(updateTicket(objParam)),
  }
}

class TicketForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      remarkResult: null,
    }
  }

  componentDidMount(){
    this.props.resetTicket(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTicket({ticketId: this.props.ticketId})
    }

    this.props.listSegment({search: {clientId: this.props.clientId}})
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateTicket({...values}) : this.props.createTicket({...values})
    
  }

  onChangeResult(v) {
    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})

    this.props.dispatch(change('TicketForm', 'progress', listRemark.progress[v]));
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    
    const { data }= (this.props.ticketStore.getTicket!=undefined && this.props.ticketStore.getTicket.progress=='1' ? this.props.ticketStore.getTicket : {})
    const { progress, code, text }= (mode==2 ? (this.props.ticketStore.updateTicket!=undefined ? this.props.ticketStore.updateTicket : {}) : (this.props.ticketStore.createTicket!=undefined ? this.props.ticketStore.createTicket : {}))

    const listSegment= (this.props.masterStore.listSegment!=undefined && this.props.masterStore.listSegment.progress=='1' ? this.props.masterStore.listSegment.data : [])
    
    return (
      <div style={{padding: '1em 1em', ...style}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Icon name='bookmark outline' style={{fontSize: '1em'}}/>
              <Header.Content>Ticket Info</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='information' component={TextAreaField}
              label='Information'
              placeholder='Information' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='segmentId' component={SelectField}
              width={8}
              options={listSegment}
              label='Segmentation'
              placeholder='Segmentation' />
              <Field name='ticketNo' component={InputField}
              width={4}
              label='Ticket No'
              placeholder='Ticket No' />
              <Field name='openStamp' component={DatePickerField}
              width={4}
              showTimeSelect={true}
              dateFormat={'dd / MMMM, yyyy h:mm aa'}
              label='Ticket Stamp'
              placeholder='Ticket Stamp' />
            </Form.Group>
          </Segment>
          
          {/* <Segment attached='top'>
            <Header as='h5'>
              <Icon name='edit outline' style={{fontSize: '1em'}}/>
              <Header.Content>Last Action</Header.Content>
            </Header>
            <Form.Group widths='equal'>
              <Field name='actionActivity' component={TextAreaField}
              label='Activity'
              placeholder='Activity' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='actionType' component={SelectField}
              width={4}
              options={actionOptions}
              label='Action Type'
              placeholder='Action Type' />
              <Field name='actionStamp' component={DatePickerField}
              width={4}
              showTimeSelect={true}
              dateFormat={'dd / MMMM, yyyy h:mm aa'}
              label='Action Stamp'
              placeholder='Action Stamp' />
              <Field name='actionHandler' component={InputField}
              width={8}
              label='Action Handler'
              placeholder='Action Handler' />
            </Form.Group>
          </Segment>
          <Segment attached='bottom'>
            <Form.Group widths='16'>
              <Field name='status' component={SelectField}
              width={4}
              options={ticketOptions}
              label='Ticket Status'
              placeholder='Ticket Status' />
              <Field name='progress' component={SelectField}
              options={progressOptions}
              width={4}
              label='Ticket Progress'
              placeholder='Ticket Progress' />
              <Field name='reason' component={InputField}
              width={8}
              label='Reason'
              placeholder='Reason' />
            </Form.Group>
          </Segment> */}
          
          {/* <Segment>
            <Header as='h5'>
              <Icon name='user outline' style={{fontSize: '1em'}}/>
              <Header.Content>Contact</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='custName' component={InputField}
              width={8}
              label='Name'
              placeholder='Full Name' />
              <Field name='custMobile' component={InputField}
              width={4}
              label='Mobile'
              placeholder='Handphone' />
              <Field name='custPhone' component={InputField}
              width={4}
              label='Phone'
              placeholder='Home / Office Phone' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='custEmail' component={InputField}
              width={4}
              label='Email'
              placeholder='Email' />
              <Field name='custTwitter' component={InputField}
              width={4}
              label='Twitter'
              placeholder='Twitter' />
              <Field name='custFacebook' component={InputField}
              width={4}
              label='Facebook'
              placeholder='Facebook' />
              <Field name='custInstagram' component={InputField}
              width={4}
              label='Instagram'
              placeholder='Instagram' />
            </Form.Group>
          </Segment> */}
          
          {/* <Segment>
            <Header as='h5'>
              <Icon name='building outline' style={{fontSize: '1em'}}/>
              <Header.Content>Corporate</Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='company' component={InputField}
                            width={8}
              label='Name'
              placeholder='Company' />
              <Field name='brand' component={InputField}
                            width={4}
              label='Brand / Code'
              placeholder='Brand / Code' />
              <Field name='business' component={InputField}
                            width={4}
              label='Business Stream'
              placeholder='Business Stream' />
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='address' component={InputField}
                            label='Address'
              placeholder='Address' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='city' component={InputField}
              width={6}
              placeholder='City' />
              <Field name='province' component={InputField}
              width={6}
              placeholder='Province' />
              <Field name='country' component={InputField}
              width={4}
              placeholder='Country' />
            </Form.Group>
          </Segment> */}

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Ticket' : 'Insert Ticket') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.ticketStore.getTicket!=undefined && state.ticketStore.getTicket.progress==1 ? state.ticketStore.getTicket.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      result: selector(state, 'result'),
      campaignType: selector(state, 'campaignType'),
      remark: selector(state, 'remark'),
    }),
    { load: getTicket }
  )
  (reduxForm({
    form: 'TicketForm',	// a unique identifier for this form
  })(TicketForm)
  )
)
