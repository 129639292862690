import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider  } from 'semantic-ui-react'

import PerformanceCampaign from '../../libraries/whatsapp/PerformanceCampaign';
import PerformanceAgent from '../../libraries/whatsapp/PerformanceAgent';
import PerformanceMessaging from '../../libraries/whatsapp/PerformanceMessaging';
import PerformanceTicket from '../../libraries/whatsapp/PerformanceTicket';

class PerformanceBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode) {
      this.setState({mode: mode})
    }

    render() {
      const {mode}= this.props
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          {this.props.campaign && <PerformanceCampaign screenWidth={screenWidth} screenHeight={screenHeight} />}
          {this.props.agent && <PerformanceAgent screenWidth={screenWidth} screenHeight={screenHeight} />}
          {this.props.messaging && <PerformanceMessaging screenWidth={screenWidth} screenHeight={screenHeight} />}
          {this.props.ticket && <PerformanceTicket screenWidth={screenWidth} screenHeight={screenHeight} />}
        </Container>
      )
    }
}

export default PerformanceBody
