import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
} from 'semantic-ui-react'

import { scheduleOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { LabelInputField, InputField, CheckboxField, RadioField, TextAreaField, SelectField, DatePickerField } from '../validation/componentrsui'
import { required, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { perfCampaign } from "../../actions/halo/performanceAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfCampaign:(objParam) => dispatch(perfCampaign(objParam)),
  }
}


class PerformanceCampaignSearch extends Component {
  constructor(props) {
    super(props)
    this.state= {
      searchMode: true,
      lastChange: null,
    }
  }

  componentDidMount() {
  }

  submitForm(values) {
    const { search }= this.state
    this.props.perfCampaign({search: {...values, ...search}})
  }

  showSearch() {
    const { searchMode }= this.state
    
    this.setState({
      searchMode: !searchMode
    })
  }

  onChangeDate(key, v) {
    const { search }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
    var values= []
    values[key]= v
    this.props.perfCampaign({search: {...search, ...values}, paging: {page: 1, size: 2}})
  }

  onChangeInput(e, v) {
    const { search }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
    const parent= this
    this.setState({
      lastChange: new Date().getTime(),
    })
    
    setTimeout(()=>{
      if (new Date().getTime()-parent.state.lastChange>=500) {
        parent.props.perfCampaign({search: {...search, keyword: v}, paging: {page: 1, size: 2}})
      }
    }, 500)
  }
  
  onChangeSelect(e, v) {
    const { search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
    this.props.perfCampaign({search: {...search, availability: v}, paging: {page: 1, size: 2}})
  }
  
  render() {
    const { searchMode }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode }=this.props
    
    return (
      <div style={{position: 'static', width: '100%', zIndex: '1000'}}>
        <div style={{padding: '0', background: 'rgba(230, 230, 230, 0)', border: '0px solid rgba(100,100,100,.2)'}}>
          <div style={{display: 'flex', padingTop: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{marginTop: '.25em'}}>
                <Icon name='calendar alternate outline' style={{fontSize: '1em'}}/>
                <Header.Content>
                  Campaign Performance
                </Header.Content>
              </Header>
            </div>
          </div>
          <div style={{margin: '1em 0', border: '0px solid rgba(100,100,100,.2)'}}>
            <Form onSubmit={handleSubmit(this.submitForm.bind(this))}>
              <div style={{display: 'flex'}}>
                <div style={{flex: '1'}}>
                  <Form.Group widths='equal'>
                    <Field name='keyword' component={InputField} onChange= {this.onChangeInput.bind(this)}
                    width={8}
                    placeholder= 'Search campaign ... <ENTER>' />
                    <Field name='availability' component={SelectField} onChange= {this.onChangeSelect.bind(this)}
                    options= {scheduleOptions()}
                    width={4} 
                    placeholder= 'Availability' />
                    <Field name='eventDate' component={DatePickerField} onChange= {this.onChangeDate.bind(this, 'eventDate')}
                    width={4} 
                    type='date'
                    placeholder= 'Event Date' />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.performanceStore.perfCampaign!=undefined && state.performanceStore.perfCampaign.progress==1 ? state.performanceStore.perfCampaign.search : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
    })
  )
  (reduxForm({
    form: 'PerformanceCampaignSearch',	// a unique identifier for this form
  })(PerformanceCampaignSearch)
  )
)