import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid, Progress, Statistic, Label } from 'semantic-ui-react'

import numeral from "numeral"

import { connect } from "react-redux"
import WebSocketProvider, { WebSocketContext } from '../../../actions/socket/Websocket';
import { socketStatus, socketWhatsappStatistic } from "../../../actions/socket/socketAction";

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}

class MessagingWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      expand: true,
    };
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const {statisticMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <div style={{display: 'flex'}}>
          <div style={{flex: '1'}}>
            <Header as='h5' style={{margin: '0'}}>
              <Icon name='fire' style={{fontSize: '1em'}} />
              <Header.Content>
                <b><i>Today!</i></b> Messaging Flow
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <div>
            {/* <Popup  content='Expand incoming or outgoing message window as your wish.' position='left center'
              trigger={
                <Icon name={this.props.modal=='expandInbound' ? 'arrow circle right' : 'arrow circle left'} onClick={this.props.openModal.bind(this, this.props.modal=='expandInbound' ? 'expandOutbound' : 'expandInbound')}  style={{fontSize: '1.35em', cursor: 'pointer'}} color={this.props.modal=='expandInbound' ? 'blue' : 'red'} />
              }
              inverted
              on={['hover']}
            /> */}
          </div>
        </div>
      </Segment>
      <Segment attached='bottom'>
        <div style={{display: 'flex', textAlign: 'center'}}>
          <div style={{flex: '1', background: '#fafafa', padding: '1em 0'}}>
            <Popup  header='Incoming Messages' content='Total received message on current day.' position='left center'
              trigger={
              <Header as='h2' style={{margin: '0', cursor: 'pointer'}}>
                <Header.Content>
                  {statisticMessage && statisticMessage.totInbound || 0}
                  <Header.Subheader style={{fontWeight: 'bold', color: '#666'}}>Incoming</Header.Subheader>
                </Header.Content>
              </Header>
              }
              inverted
              on={['hover']}
            />
          </div>
          <div style={{flex: '1', background: '#fafafa', padding: '1em 0', borderLeft: '1px solid #ccc'}}>
            <Popup  header='Outgoing Messages' content='Total message delivery on current day.' position='left center'
              trigger={
                <Header as='h2' style={{margin: '0', cursor: 'pointer'}}>
                  <Header.Content>
                    {statisticMessage && statisticMessage.totOutbound || 0}
                    <Header.Subheader style={{fontWeight: 'bold', color: '#666'}}>Outgoing</Header.Subheader>
                  </Header.Content>
                </Header>
              }
              inverted
              on={['hover']}
            />
          </div>
        </div>
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingWidget)