import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Dropdown } from 'semantic-ui-react'

import { parse, format, endOfMonth } from "date-fns"

import LineRechart from '../../chart/LineRechart';

import { connect } from "react-redux"
import { summaryCampaign } from "../../../actions/stream/campaignAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    campaignStore: state.campaignStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    summaryCampaign:(objParam) => dispatch(summaryCampaign(objParam)),
  }
}

class TrafficWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      stamp: '',
      barData: [
        // {name: '01', totSent: 1, totDlvrd: 5, totRead: 15, totPlayed: 1, totReplied: 5}, 
        // {name: '02', totSent: 2, totDlvrd: 6, totRead: 9, totPlayed: 1, totReplied: 5}, 
        // {name: '03', totSent: 3, totDlvrd: 7, totRead: 5, totPlayed: 1, totReplied: 5}, 
        // {name: '04', totSent: 4, totDlvrd: 8, totRead: 7, totPlayed: 1, totReplied: 5}, 
      ],
      barLegend: [
        {id: 'totSent', name: 'Sent'},
        {id: 'totDlvrd', name: 'Delivered'},
        {id: 'totRead', name: 'Read'},
        {id: 'totPlayed', name: 'Played'},
        {id: 'totReplied', name: 'Replied'},
        {id: 'totInbound', name: 'Inbound'},
        {id: 'totOutbound', name: 'Outbound'},
      ]
    };
  }
  
  componentDidUpdate() {
    const { barData, stamp}= this.state
    const { data, search }= (this.props.campaignStore.summaryCampaign!==undefined ? this.props.campaignStore.summaryCampaign : {})

    const eom= search && search.groupingDate ? format(endOfMonth(parse(search.groupingDate.substr(0,11), 'yyyy-MM-dd', new Date())), 'd') : null;
    const _stamp= (search && search.groupingDate && (search.grouping=='Daily' ? search.grouping +'-'+ search.groupingDate.substr(8,2) : search.grouping +'-'+ search.groupingDate.substr(5,2))) || '';

    if (this.state.stamp!=_stamp) {
      if (eom>0) {
        // console.log('!!! DATA', data, search, eom)
      
        const bardata= new Array(eom);
        for (var i=0; i<eom; i++) {
          bardata[i]= {
            name: i+1, 
            totSent: 0, 
            totDlvrd: 0, 
            totRead: 0, 
            totPlayed: 0, 
            totReplied: 0,
            totInbound: 0,
            totOutbound: 0,
          }
        }

        if (search && data && data.populations) {
          if (search && search.grouping=='Daily') {
            Object.entries(data.populations).forEach(([dd, value]) => {
              // console.log('!!! DAILY', search.groupingDate && search.groupingDate.substr(8,2), dd, value)
              if (search.groupingDate && search.groupingDate.substr(8,2)==dd) {
                bardata[dd-1].totSent= parseInt(value.totSent);
                bardata[dd-1].totDlvrd= parseInt(value.totDlvrd);
                bardata[dd-1].totRead= parseInt(value.totRead);
                bardata[dd-1].totPlayed= parseInt(value.totPlayed);
                bardata[dd-1].totReplied= parseInt(value.totReplied);
                bardata[dd-1].totInbound= parseInt(value.totInbound);
                bardata[dd-1].totOutbound= parseInt(value.totOutbound);
              }
            });
          } else if (search && search.grouping=='Monthly') {
            Object.entries(data.populations).forEach(([dd, value]) => {
              // console.log('!!! MONTHLY', dd, value)
              bardata[dd-1].totSent= parseInt(value.totSent);
              bardata[dd-1].totDlvrd= parseInt(value.totDlvrd);
              bardata[dd-1].totRead= parseInt(value.totRead);
              bardata[dd-1].totPlayed= parseInt(value.totPlayed);
              bardata[dd-1].totReplied= parseInt(value.totReplied);
              bardata[dd-1].totInbound= parseInt(value.totInbound);
              bardata[dd-1].totOutbound= parseInt(value.totOutbound);
            });
          }
        }

        // bardata.map((value, i)=>{
        //   console.log('!!! VALUE', value, i)
        // })
        
        this.setState({
          stamp: _stamp,
          barData: [
            ...bardata,
          ]
        });
      }
    }
  }
  
  render() {
    const { screenWidth,  screenHeight, height}= this.props
    const { barData, barLegend}= this.state
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Traffic Statistic / {this.state.stamp}
            <Header.Subheader>Traffic delivery on time and daily basis.</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: height}}>
        {/* {data!=undefined && */}
          <LineRechart mode='simple' height={(height-55) || 250} yAxis={false} dashed={true} label={true} data={barData} legend={barLegend}/>
        {/* } */}
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TrafficWidget)