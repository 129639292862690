import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Button } from 'semantic-ui-react'

import MessagingBrowse from '../../libraries/whatsapp/MessagingBrowse'
import MessagingSearch from '../../libraries/whatsapp/MessagingSearch'

class MessagingBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, messagingId: id})
    }

    render() {
      const {mode, messagingId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{marginTop: '-3em', height: '3em'}}>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'campaign', this.props.params)}><Icon name='edit' />Campaign</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'target', this.props.params)}><Icon name='user circle' />Target</Button>
            <Button color='blue' onClick={this.props.openModal.bind(this, 'messaging', this.props.params)}><Icon name='check square outline' />Delivery</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'impression', this.props.params)}><Icon name='chart line' />Impression</Button>
          </div>

          <MessagingSearch mode={mode} setMode={this.setMode.bind(this)} screenWidth={screenWidth} />
          <div>
            {mode==1 && <MessagingBrowse campaignId={this.props.params && this.props.params.campaignId} setMode={this.setMode.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} openModal={this.props.openModal} />}
          </div>
        </Container>
      )
    }
}

export default MessagingBody