import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Divider } from 'semantic-ui-react'
import ChatboxConversation from '../../libraries/whatsapp/ChatboxConversation';
import ContactBody from './ContactBody';

// import ApiForm from '../../libraries/whatsapp/ApiForm'


class QuickchatBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 2, //1: browse, 2: edit, 3: insert, 4: delete
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, coachId: id})
    }

    render() {
      const {mode}= this.state
      const {screenWidth, screenHeight}= this.props
      
      return(
        <Container fluid>
          <div style={{margin: '1em 1em 1em 1em'}}>
            <ContactBody screenHeight={screenHeight} params={this.props.params} mode={this.props.mode} openModal={this.props.openModal} />
            {/* <ChatboxConversation screenHeight={screenHeight} params={this.props.params} mode={this.props.mode} /> */}
          </div>
        </Container>
      )
    }
}

export default QuickchatBody
