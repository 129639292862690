import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Card, Progress, Label } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import { connect } from "react-redux"
import { perfRecent } from "../../actions/halo/performanceAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfRecent:(objParam) => dispatch(perfRecent(objParam)),
  }
}


class RecentWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }
  
  componentDidMount() {
    this.props.perfRecent() 
  }
  
  render() {
    const { screenWidth,  screenHeight}= this.props
    const { data, search, paging }= (this.props.performanceStore && this.props.performanceStore.perfRecent ? this.props.performanceStore.perfRecent : {})
    
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='calendar alternate outline' style={{fontSize: '1em'}} />
          <Header.Content>
            Recent Campaign
            <Header.Subheader></Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em'}}>
        <Card.Group itemsPerRow={screenWidth<1100 ? 2 : 3} stackable>
          {data && data.map((item, i)=>{
            return(
              <Card key={i} as='a' onClick={this.props.openModal.bind(this, 'campaign_statistic', {campaignId: item.campaignId})}>
                {item.avatar!=null ?  <Image src={item.avatar} wrapped ui={false} /> : <Card.Content extra style={{textAlign: 'center', paddingTop: '10em'}}>
                  {/* <Icon name='calendar alternate outline' color='teal' style={{fontSize: '10em'}}/> */}
                  <Icon  style={{fontSize: '10em'}} name={item.isEnabled=='1'? 'calendar alternate outline' : 'calendar alternate outline'} color={(item.isEnabled=='0' && 'grey') || (item.isExpired=='-1' && 'yellow') || (item.isExpired=='1' && 'red') || 'teal'} />
                </Card.Content>}
                <Card.Content>
                  <Card.Meta><b>{item.campaignType}</b> <b>{(item.isEnabled=='0' && <i> (Disabled)</i>) || (item.isExpired=='-1' && <i> (Upcoming)</i>) || (item.isExpired=='1' && <i> (Expired)</i>)}</b></Card.Meta>
                  <Card.Header style={{fontWeight: 'bold', color: ((item.isEnabled=='0' || item.isExpired!='0') && '#a66942') }}><span style={{textDecoration: (item.isEnabled=='0' || item.isExpired!='0') ? 'line-through' : 'none'}}>{item.name}</span></Card.Header>
                  <Card.Meta>{item.startTime}-{item.endTime}
                  <br />{item.startDate && format(parse(item.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM')} - {item.endDate && format(parse(item.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy')}
                  {item.client && <><br />{item.client}</>}
                  </Card.Meta>
                  <Card.Description>{item.descript || 'No description available'}</Card.Description>
                </Card.Content>
                <Card.Content extra>
                  {/* <Progress progress size='medium' value={item.totDelivered} total={item.totTarget} indicating percent={Math.round(item.totTarget>0 ? (item.totDelivered / item.totTarget) * 100 : 0)} /> */}
                  <div style={{display: 'flex'}}>
                    <div style={{padding: '10px 1em 0 0', marginRight: '-1.3em'}}>
                      <Label size='medium' color='orange' style={{borderRadius: '1em 0 0 1em', height: '27px'}}>COMPLETENESS ({item.totComplete<1000 ? item.totComplete : numeral(item.totComplete).format('0.0a')})</Label>
                    </div>
                    <div style={{flex: '1', padding: '.8em 0 0 0'}}>
                      <Progress color='red' size='medium' percent={Math.round(item.totTarget>0 ? ((item.totComplete / item.totTarget)>1 ? 1 : (item.totComplete / item.totTarget)) * 100 : 0)} progress style={{margin: '0'}}/>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            )
          })}
        </Card.Group>
      </Segment>
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentWidget)