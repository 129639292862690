import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, formatDistance, formatRelative, } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseMessaging, statusMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessaging:(objParam) => dispatch(browseMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),
  }
}

class ChatboxMessagingBrowse extends Component {
  constructor(props) {
    super(props)
    this.state= {
      archive: false,
      expand: false,
    }
  }

  doPaging(e, page) {
    const { search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    this.props.browseMessaging(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    this.props.browseMessaging({search: {
      // transactDate: format(new Date(), 'yyyy-MM-dd 00:00:00'), 
    // ...search, 
    // ...(this.props.campaignId ? {campaignId: this.props.campaignId} :{})
      terminating: this.props.params && this.props.params.terminating
    }, paging: {page: 1, size: 5}})
  }

  onChangeStatus(id, status, e) {
    // console.log(id, status, e)
    this.props.statusMessaging({messagingId:id, isEnabled: status})
  }
  setMode(item) {
    const { archive }= this.state
    this.setState({
      archive: !archive,
      archiveItem: item,
    })
  }

  render() {
    //console.log(this.props.messagingStore.browseMessaging)
    const { expand, archive }= this.state
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.messagingStore.browseMessaging!==undefined ? this.props.messagingStore.browseMessaging : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);

    const statusMesg= 0;
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', height: '100%'}}>
        <div>
          <div style={{textAlign: 'center'}}>
            <Header as='h4' icon style={{margin: '0'}}>
              <Icon name='comment alternate' circular inverted color='yellow' />
              <Header.Content>
                <b><i>Recent!</i></b> Conversation
                <Header.Subheader>Historical chat / conversation session</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          <Divider horizontal>
            {archive ? <Icon name='times' color='red' onClick={this.setMode.bind(this, false)} style={{fontSize: '1.2em', cursor: 'pointer'}}/> : <Icon name='warning' disabled style={{fontSize: '1.2em'}}/>}
          </Divider>
          {data!=undefined && data.length==0 && <div style={{}}>No recent historical chat / conversation session is available for this current customer.</div>}
        </div>
        {!archive && <div style={{flex: '1', padding: '0'}}>
          <Feed>
            { data!==undefined ? data.map((item,i)=>{
              return(
                <Feed.Event key={i} style={{borderRadius: '.3em', background: (i%2==0 ? '#fdfdfd99' : '#fdfdfdbb'), padding: '0 .5em', borderTop:i!=0 ? '1px solid #fff' : 'none', borderBottom:i!=(data.length-1) ? '1px solid rgba(100, 100, 100, .1)' : 'none'}}>
                  <Feed.Label style={{margin: '.5em 0 .5em 0', height: '6em', width: '6em', background: (i%2==0 ? '#eeeeee77' : '#eeeeeeaa'), borderRadius: '.3em', textAlign: 'center'}}>
                    <div style={{fontSize: '1.5em', fontWeight: 'bold', padding: '1em 0 .1em 0'}}>{item.sentStamp && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm')}</div>
                    <div style={{fontSize: '.8em', fontWeight: 'bold'}}>{item.sentStamp && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'dd/MMM yy')}</div>
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Extra>
                      <div style={{fontWeight: statusMesg==5 && item.conversations && item.conversations.length==1 ? 'bold' : 'normal'}}>
                        {item.conversations && item.conversations[0] && item.conversations[0].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />} 
                        <span>{(item.conversations && item.conversations[0] && (item.conversations[0].message && item.conversations[0].message.length>20 ? item.conversations[0].message.substr(0,20)+ '...' : item.conversations[0].message)) || (item.content && item.content.length>20 ? item.content.substr(0,20)+ '...' : item.content)}</span>
                      </div>
                      {item.conversations && item.conversations.length>1 && <>
                        <Divider style={{margin: '.2em 0'}} horizontal>
                        <Icon name='ellipsis vertical' color='teal' style={{cursor: 'pointer', margin: '0'}} onClick={this.setMode.bind(this, item)}/>
                        </Divider>
                        {item.conversations && item.conversations[item.conversations.length-1] && item.conversations[item.conversations.length-1].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />}
                        <span><b>{(item.conversations && item.conversations[item.conversations.length-1] && (item.conversations[item.conversations.length-1].message && item.conversations[item.conversations.length-1].message.length>20 ? item.conversations[item.conversations.length-1].message.substr(0,20)+ '...' : item.conversations[item.conversations.length-1].message))}</b></span>
                      </>}
                    </Feed.Extra>
                    <Feed.Extra>
                      <div style={{display: 'flex'}}>
                        <div style={{flex: 1}}>
                          <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em'}}>
                            {(item.clickStamp && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.readStamp && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.receivedStamp && format(parse(item.receivedStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.deliveredStamp && format(parse(item.deliveredStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.sentStamp && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM '))}
                          </Feed.Date>
                        </div>
                        <div style={{margin: '-1em 0 0 .5em'}}>
                          {item.conversations && item.conversations.length>0 && <Feed.Date>
                            <Popup content={(item.readStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'Read') || (item.deliveredStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'Delivered') || (item.sentStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'Sent') || (item.receivedStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'Reply Now') || 'Sent'} position='left center' inverted trigger={
                              <Icon onClick={(this.props.params && this.props.params.messagingId==item.messagingId) ? null : this.setMode.bind(this, item)}
                              name={(item.status<0 && 'times') || (item.readStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'eye') || (item.deliveredStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (item.sentStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (item.receivedStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'alarm') || 'ellipsis horizontal'} 
                              color={(item.status<0 && 'red') || (item.readStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'brown') || (item.deliveredStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'blue') || (item.sentStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'grey') || (item.receivedStamp_>=format(new Date(item.conversations[item.conversations.length-1].stamp), 'yyyy-MM-dd HH:mm:ss') && 'red') || 'grey'}
                              style={{cursor: 'pointer', margin: '0', padding: '0'}}/>
                            } />
                          </Feed.Date>}
                        </div>
                      </div>
                    </Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
              )
              }) : <b><Icon name='circle notch' loading />loading ...</b>
            }
          </Feed>
        </div>}

        {!archive && paging!=undefined && paging.count>0 &&
          <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'center'}}>
            <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
              defaultActivePage={paging.page}
              totalPages={Math.ceil(paging.count/paging.size)}
              ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
              firstItem={{ content: <Icon name='angle double left' />, icon: true }}
              lastItem={{ content: <Icon name='angle double right' />, icon: true }}
              prevItem={{ content: <Icon name='angle left' />, icon: true }}
              nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
          </div>
        }

        {archive && <div id='wd_chat' className='chat' style={{padding: '.1em 0', overflowY: 'auto', height: screenHeight-240, border: '1px solid #eee', borderRadius: '.3em'}}>
          <div style={{padding: '.2em 0em', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            {
              this.state.archiveItem && this.state.archiveItem.conversations.map((item, idx)=>{
                return(
                  <div key={idx} style={{margin: '.1em .3em', textAlign: item.sender=='cust' ? 'left' : 'right'}}>
                    <div style={{background: item.sender=='cust' ? '#f7f7f7' : '#f7f7f7', float: item.sender=='cust' ? 'left' : 'right', padding: item.sender=='cust' ? '.5em 1em .2em .5em' : '.5em .5em .2em 1em', margin: item.sender=='cust' ? '0 2em 0 0' : '0 0 0 2em', borderRadius: item.sender=='cust' ? '1em 2.5em 2.5em 0em' : '2.5em 1em 0em 2.5em'}}>
                      <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                        <Header.Content style={{color: '#444', fontSize: '11pt', overflowWrap: 'anywhere'}}>
                          <Header.Subheader style={{fontSize: '.8em', borderBottom: '1px solid rgba(100, 100, 100, .5)'}}> ({idx+1}) {item.sender=='fadfa agent' ? this.props.params.target.name || 'Guest' : ''} <b style={{color: '#900'}}>{item.stamp && format(new Date(item.stamp), 'HH:mm')}</b>{item.stamp && format(new Date(item.stamp), ', dd/MMM yy')}</Header.Subheader>
                          {item.message}
                          {item.sender=='agent' &&
                          <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}>
                            <Icon 
                            name={(this.state.archiveItem.status<0 && 'times') || (this.state.archiveItem.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'eye') || (this.state.archiveItem.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (this.state.archiveItem.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || 'ellipsis horizontal'} 
                            color={(this.state.archiveItem.status<0 && 'red') || (this.state.archiveItem.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'brown') || (this.state.archiveItem.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'blue') || (this.state.archiveItem.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'grey') || 'black'}
                            style={{margin: '0', padding: '0'}}/>
                            </Header.Subheader>}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              })
            }
            <div style={{flex: '1', textAlign: 'right'}} />
          </div>
        </div>}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatboxMessagingBrowse)
