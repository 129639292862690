import React from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon, Button } from 'semantic-ui-react'

import CampaignPerformance from '../../libraries/whatsapp/CampaignPerformance'

class ImpressionBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        campaignId: 0,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{marginTop: '-3em', height: '3em'}}>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'campaign', this.props.params)}><Icon name='edit' />Campaign</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'target', this.props.params)}><Icon name='user circle' />Target</Button>
            <Button color='grey' onClick={this.props.openModal.bind(this, 'messaging', this.props.params)}><Icon name='check square outline' />Delivery</Button>
            <Button color='blue' onClick={this.props.openModal.bind(this, 'impression', this.props.params)}><Icon name='chart line' />Impression</Button>
          </div>
          <CampaignPerformance item={this.props.params} setMode={this.setMode.bind(this)} />
        </Container>
      )
    }
}

export default ImpressionBody
