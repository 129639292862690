import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
} from 'semantic-ui-react'

import MessagingInboundRecent from '../../libraries/whatsapp/MessagingInboundRecent';
import MessagingOutboundRecent from '../../libraries/whatsapp/MessagingOutboundRecent';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import QueueingWidget from '../../libraries/widget/whatsapp/QueueingWidget';
import ChatboxConversation from '../../libraries/whatsapp/ChatboxConversation';

class ChatBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1.5em'}}>
              <div style={{display: 'flex'}}>
                <div className='animatewidth' style={{width: 350, background: '#f9f9f9', marginRight: '1.5em'}}>
                  <Segment style={{height: screenHeight}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <div style={{flex: '1', margin: '-1.5em -1.5em 1em -1.5em', padding: '1em 1em', borderBottom: '1px dashed #47b9ffcc'}}>
                        <div style={{display: 'flex'}}>
                          <div style={{padding: '.3em 1em 0 0'}}>
                            <Header as='h5'>
                              <Header.Content>
                                <b>Search</b>
                                <Header.Subheader>Phone or name</Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Input loading icon='user' placeholder='Search...' fluid />
                          </div>
                        </div>
                      </div>
                      <div>
                        SEARCH RESULT  
                      </div>
                      <div style={{flex: 1, margin: '0 -1.5em  -.5em  -1.5em  ', height: screenHeight, overflowY: 'auto'}}>
                        <div style={{margin: '0 1em 1.5em 1em'}}>
                          <MessagingInboundRecent openModal={this.props.openModal} />
                        </div>
                      </div>
                    </div>
                  </Segment>
                </div>
                <div className='animatewidth' style={{flex: 1, background: '#f9f9f9'}}>
                  <Segment style={{height: screenHeight}}>
                    <ChatboxConversation params={this.props.params} mode={this.props.mode} screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.props.openModal} closeModal={this.props.closeModal} />
                  </Segment>
                </div>
              </div>
            </div>
            {screenWidth>=1600 && <div style={{width: 350, background: '#f5f5f5', padding: '2em 1.5em'}}>
              <QueueingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} modal={this.props.modal}/>
              <CampaignWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <BillingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>}
          </div>
        </Container>
      )
    }
}

export default ChatBody
