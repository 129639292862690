import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Icon } from 'semantic-ui-react'

import { protoOptions, gatewayOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'

import { SubmissionError, Field, reduxForm } from 'redux-form'
import { ImageUploadField, LabelInputField, NumberField, InputField, CheckboxField, SelectField, DatePickerField, MaskedField } from '../validation/componentrsui'
import { required, number, minValue, email, phoneNumberID } from '../validation/validation'
import { connect } from "react-redux"
import { resetWaserver, getWaserver,updateWaserver, createWaserver } from "../../actions/whatsapp/waserverAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    waserverStore: state.waserverStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetWaserver:(objParam) => dispatch(resetWaserver(objParam)),
    getWaserver:(objParam) => dispatch(getWaserver(objParam)),
    createWaserver:(objParam) => dispatch(createWaserver(objParam)),
    updateWaserver:(objParam) => dispatch(updateWaserver(objParam)),
  }
}

class WaserverForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

    }
  }

  componentDidMount(){
    this.props.resetWaserver(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getWaserver({waserverId: this.props.waserverId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.waserverStore.updateWaserver!=undefined ? this.props.waserverStore.updateWaserver : {}) : (this.props.waserverStore.createWaserver!=undefined ? this.props.waserverStore.createWaserver : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode, waserverId } = this.props
    mode==2 ? this.props.updateWaserver({...values}) : this.props.createWaserver({...values})
  }
  

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.waserverStore.updateWaserver!=undefined ? this.props.waserverStore.updateWaserver : {}) : (this.props.waserverStore.createWaserver!=undefined ? this.props.waserverStore.createWaserver : {}))

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Whatsapp Server
                <Header.Subheader>Whatsapp server information.</Header.Subheader>
              </Header.Content>
            </Header>
            {/* <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField} style={{marginTop: '2.5em'}}
              width={6}
              toggle
              label= 'Enable Now !' />
              <Field name='isSuspended' component={CheckboxField} style={{marginTop: '2.5em'}}
              toggle
              width={6}
              label='Suspend Now !'
              placeholder='Status suspended' />
            </Form.Group> */}
            <Form.Group widths='16'>
              <Field name='account' component={InputField}
              width={6}
              validate={[required, phoneNumberID]}
              label='Whatsapp Account'
              placeholder='Whatsapp Account' />
              <Field name='container' component={InputField}
              width={10}
              label='Server Container'
              placeholder='Server Container' />

              {/* <Field name='owner' component={InputField}
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='equal'>
              <Field name='streams' component={SelectField}
              multiple
              validate={[required]}
              options={gatewayOptions('direction')}
              label='Message Streams / Flow'
              placeholder='Dedicated mesage streams direction, interactive only, broadcast only or boths' />
            </Form.Group>
          </Segment>
          <Segment>
            <Header as='h5'>
              <Header.Content>
                Service Provisioning
                <Header.Subheader>Service provisioning and creation.</Header.Subheader>
              </Header.Content>
            </Header>
            <Form.Group widths='16'>
              <Field name='createStamp' component={InputField}
              readOnly
              width={6}
              label='Create Stamp'
              placeholder='Create Stamp' />
              <Field name='createResult' component={InputField}
              readOnly
              width={10}
              label='Create Result'
              placeholder='Create Result' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='authenticateStamp' component={InputField}
              readOnly
              width={6}
              label='Authenticate Stamp'
              placeholder='Authenticate Stamp' />
              <Field name='authenticateResult' component={InputField}
              readOnly
              width={10}
              label='Authenticate Result'
              placeholder='Authenticate Result' />
            </Form.Group>
            <Form.Group widths='16'>
              <Field name='terminateStamp' component={InputField}
              readOnly
              width={6}
              label='Terminate Stamp'
              placeholder='Terminate Stamp' />
              <Field name='terminateResult' component={InputField}
              readOnly
              width={10}
              label='Terminate Result'
              placeholder='Terminate Result' />
            </Form.Group>
          </Segment>

          <Segment>
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Waserver' : 'Insert Waserver') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' content='Submit' color='red' disabled={this.props.pristine || this.props.submitting} />
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.waserverStore.getWaserver!=undefined && state.waserverStore.getWaserver.progress==1 ? state.waserverStore.getWaserver.data : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: true,
      keepSubmitSucceeded:true,
    }),
  )
  (reduxForm({
    form: 'WaserverForm',	// a unique identifier for this form
  })(WaserverForm)
  )
)