import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Pagination,
  Table,
  Progress,
} from 'semantic-ui-react'

import numeral from "numeral"
import { parse, format } from "date-fns"

import { connect } from "react-redux"
import { browseLink } from "../../../actions/talk/linkAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    linkStore: state.linkStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseLink:(objParam) => dispatch(browseLink(objParam)),
  }
};


class LinkBrowse extends Component {
  constructor(props) {
    super(props);
    this.state= {

    }
  }
  
  doPaging(e, page) {
    const { search, paging }= (this.props.linkStore.browseLink!==undefined ? this.props.linkStore.browseLink : {})
    this.props.browseLink(
      {
        paging: {...paging, page: page.activePage},
        search: search
      }
    )
  }
  
  componentDidMount() {
    const { search, paging }= (this.props.linkStore.browseLink!==undefined ? this.props.linkStore.browseLink : {})
    this.props.browseLink({search: {...search, conferenceId: this.props.conferenceId}, paging: paging})
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    const { data, search, paging }= (this.props.linkStore.browseLink!==undefined ? this.props.linkStore.browseLink : {})
    let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
    
    return (
      <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight-125}}>
        <div style={{flex: '1'}}>
        <Table unstackable selectable columns='16' celled striped basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell width='7'>
                  <Header as='h5' color='blue'>
                    NAME
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    CONFERENCE
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    EXPIRED
                  </Header>
                </Table.HeaderCell>
                <Table.HeaderCell width='3'>
                  <Header as='h5' color='blue'>
                    CONVERSATION
                  </Header>
                </Table.HeaderCell>
                {/* <Table.HeaderCell>
                </Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { data!=undefined && data.length==0 &&<Table.Row><Table.Cell colSpan={10} style={{padding: '1em 2em'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></Table.Cell></Table.Row>}
              { data!==undefined ? data.map((item,key)=>{
                  return(
                    <Table.Row key={++seq}>
                      <Table.Cell>{seq}</Table.Cell>
                      {/* <Table.Cell textAlign='center'>
                        <Icon style={{cursor: 'pointer'}} name='edit' onClick={this.props.setMode.bind(this, '2',item.linkId)}/>
                      </Table.Cell> */}
                      <Table.Cell>
                        {item.conference &&
                        <Header as='h5'>
                          <Header.Content>
                            {item.conference.name}
                            <Header.Subheader>{item.conference.baseUrl}{item.code}</Header.Subheader>
                          </Header.Content>
                        </Header>
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {item.conference &&
                        <Header as='h5'>
                          <Header.Content>
                            {item.conference.audience}
                            <Header.Subheader>{item.conference.joinType} / {item.conference.method}</Header.Subheader>
                          </Header.Content>
                        </Header>
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {/* {item.conference &&
                        <Header as='h5'>
                          <Header.Content>
                              {item.conference.startDate!=null ? format(parse(item.conference.startDate, 'yyyy-MM-dd', new Date()), 'dd / MMM') : ''} - 
                              {item.conference.endDate!=null ? format(parse(item.conference.endDate, 'yyyy-MM-dd', new Date()), 'dd / MMM, yyyy') : ''}
                              <Header.Subheader>{item.conference.startTime} - {item.conference.endTime}</Header.Subheader>
                          </Header.Content>
                        </Header>} */}
                        <Header as='h5'>
                          <Header.Content>
                              {/* {item.createTime!=null ? format(parse(item.createTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss') : ''} -  */}
                              {item.expireTime!=null ? format(parse(item.expireTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'HH:mm:ss') : '-'}
                              <Header.Subheader>
                                {/* {item.createTime!=null ? format(parse(item.createTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM') : ''} -  */}
                                <b>{item.expireTime!=null ? format(parse(item.expireTime, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd / MMM, yyyy') : 'Not Shared'}</b>
                              </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign='right'>
                        <Header as='h5'>
                          <Header.Content>
                            {numeral(item.totConversation || '0').format('0,0')} / {numeral(item.maxConversation || '0').format('0,0')}
                            <Header.Subheader>Conversation usages</Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      {/* <Table.Cell>
                        <Button icon labelPosition='left' onClick={this.props.setMode.bind(this, '2', item.linkId, item)}><Icon color='blue' name={item.channels && item.channels.indexOf('Video')>0 ? 'video' : 'microphone'} /> Call</Button>
                      </Table.Cell> */}
                    </Table.Row>
                  )
                }) : <Table.Row><Table.Cell colSpan={3}><Icon name='circle notch' loading />loading ...</Table.Cell></Table.Row>
              }
            </Table.Body>
          </Table>
        </div>
        
        {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
               
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkBrowse)
