import React, { Component } from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Item,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Table,
  Label,
  Progress,
  Pagination,
} from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"
import { connect } from "react-redux"
import { perfCampaign } from "../../actions/halo/performanceAction"
import RadialRechart from '../chart/RadialRechart';
import PieRechart from '../chart/PieRechart';
import PerformanceCampaignSearch from './PerformanceCampaignSearch';
import CampaignWidget from '../widget/CampaignWidget';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    performanceStore: state.performanceStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    perfCampaign:(objParam) => dispatch(perfCampaign(objParam)),
  }
};

class PerformanceCampaign extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1, //1: browse, 2: edit, 3: insert, 4: delete
        userId: 0,
      };
    }
  
    doPaging(e, page) {
      const { search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      this.props.perfCampaign(
        {
          paging: {...paging, page: page.activePage},
          search: search
        }
      )
    }
    
    componentDidMount() {
      const { search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      this.props.perfCampaign({search: {eventDate: format(new Date(), 'yyyy-MM-dd'), ...search}, paging: {page: 1, ...paging, size: 2}})
    }

    render() {
      const {screenWidth, screenHeight}= this.props
      const { data, search, paging }= (this.props.performanceStore.perfCampaign!==undefined ? this.props.performanceStore.perfCampaign : {})
      let seq= (paging!=undefined ?  (paging.page!=undefined && paging.size!=undefined ? (paging.page - 1) * paging.size : 0) : 0);
      
      return(
        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: screenHeight}}>
          <div>
            <PerformanceCampaignSearch />
          </div>
          <div style={{flex: '1'}}>
            { data!=undefined && data.length==0 &&<Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label>}
            <Item.Group>
              { 
                data!=undefined ? data.map((item,key)=>{
                  return(
                    <CampaignWidget seq={++seq} key={key} item={item} screenWidth={screenWidth} />
                  )
                }) : <Segment><Icon name='circle notch' loading />loading ...</Segment>
              }
            </Item.Group>
          </div>
          {paging!=undefined && paging.count>0 &&
            <div style={{marginTop: '1em', padding: '0 0', borderTop: '0px solid rgba(100,100,100,.2)', textAlign: 'right'}}>
              <Pagination onPageChange={this.doPaging.bind(this)} size='mini'
                defaultActivePage={paging.page}
                totalPages={Math.ceil(paging.count/paging.size)}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
              />
            </div>
          }
        </div>
      )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCampaign)
