import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, LabelNumberField, LabelDatePickerField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetVisitor, getVisitor,updateVisitor } from "../../actions/halo/visitorAction"
import { contactOmniphone,remarkOmniphone, resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ChatboxCustomerForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    visitorStore: state.visitorStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),

    resetVisitor:(objParam) => dispatch(resetVisitor(objParam)),
    getVisitor:(objParam) => dispatch(getVisitor(objParam)),
    updateVisitor:(objParam) => dispatch(updateVisitor(objParam)),

    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
  }
}

class ChatboxCustomerForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isRemark: true,
      lastVisitor: null,
    }
  }

  componentDidMount(){
    this.props.listRemark({remarkType: 'visitor'});

    this.props.resetVisitor(this.props.mode)
    this.props.getVisitor({visitorId: this.props.visitorId})
  }

  componentDidUpdate(prevProps) {
    const { mode, visitor, media } = this.props
    const { lastVisitor } = this.state
    
    const { progress, code, text }= this.props.visitorStore.updateVisitor!=undefined ? this.props.visitorStore.updateVisitor : {};
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.hasSaved();
      }, 3000 )
    } else {
      if (prevProps.visitorId!=this.props.visitorId) {
        this.props.getVisitor({visitorId: this.props.visitorId})
      }
    }

    if (visitor && visitor.name) {
      if (!lastVisitor || visitor.name!=lastVisitor.name)  {
        this.props.dispatch(change('ChatboxCustomerForm', 'name', visitor.name ));
        this.props.dispatch(change('ChatboxCustomerForm', 'mobile', visitor.mobile ));
        this.props.dispatch(change('ChatboxCustomerForm', 'email', visitor.email ));

        this.setState({
          lastVisitor: visitor
        })
      }
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { media } = this.props
    this.props.updateVisitor({...values})
    this.props.remarkOmniphone({...values, voices: null, appointments: null })

    this.props.endConversation(media, 
      (media=='Chat' && {
        command: 'end_by_agent',
        visitorId: this.props.visitorId,
        agentId: this.props.agentId,
        clientId: this.props.clientId,
      })
    )
  }

  setMode() {
    this.setState({isRemark: !this.state.isRemark})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, style } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.visitorStore.updateVisitor!=undefined ? this.props.visitorStore.updateVisitor : {}) : (this.props.visitorStore.createVisitor!=undefined ? this.props.visitorStore.createVisitor : {}))
    const { data }= this.props.visitorStore.getVisitor!=undefined ? this.props.visitorStore.getVisitor : {}

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{margin: '0 0em', height: '100%'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} style={{height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{...(!this.state.isRemark ? {flex: 1} : {overflow: 'hidden'}), padding: '1em 1em'}}>
              {/* <div style={{textAlign: 'center'}}>
                <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='small' circular style={{display: 'inline-block'}}/>
                <Icon name='user circle' color='yellow' style={{fontSize: '8em', margin: '0'}} />
                <Header as='h4'>
                  <Header.Content>
                    {this.props.params && ((this.props.params.target && this.props.params.target.name) || (this.props.params.customer && this.props.params.customer.name) || (this.props.params.visitor && this.props.params.visitor.name) || 'Guest / Visitor')}
                    <Header.Subheader>{this.props.params && ((this.props.params.target && 'Campaign target contact') || (this.props.params.customer && 'Customer data') || (this.props.params.visitor && 'Public visitor'))}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div> */}
              <div style={{textAlign: 'center', marginBottom: '2em'}}>
                <Header as='h3' icon>
                  <Icon name='shopping bag' style={{fontSize: '.8em'}} circular inverted color='blue' />
                  <Header.Content>
                    Customer
                    <Header.Subheader>Personal data and remark/feedback update</Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider style={{margin: '.3em 0'}}/>
                <span>Ingestion is available from <b>Secured Local Relay API</b> for enhance security level, contact your Admin to connect.</span>
              </div>

              <Form.Group widths='equal'>
                <Field name='name' component={InputField}
                // label='Name'
                validate={required}
                placeholder='Full Name' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='email' component={InputField}
                // label='Email'
                validate={email}
                placeholder='Email (Optional)' />
              </Form.Group>
              {/* <Form.Group widths='equal'>
                <Field name='whatsapp' component={InputField}
                label='Whatsapp'
                validate={[required, phoneNumberID]}
                placeholder='Whatsapp' />
              </Form.Group> */}
              
              {!this.state.isRemark && <>
                <Header as='h5'>
                  <Icon name='id card outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Personal</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='gender' component={SelectField}
                  options={genderOptions}
                  // label='Gender'
                  placeholder='L/P (Optional)' />
                  <Field name='birthDate' component={DatePickerField}
                  // label='Birth Date'
                  placeholder='(Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='keyword' component={TextAreaField}
                  // label='Description'
                  rows={2}
                  placeholder='Short description (Optional)' />
                </Form.Group>
              
                <Header as='h5'>
                  <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                  <Header.Content>Address</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='address' component={InputField}
                  placeholder='Address (Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='city' component={InputField}
                  placeholder='City (Optional)' />
                  <Field name='province' component={InputField}
                  placeholder='Province (Optional)' />
                </Form.Group>
              </>}
              {/* <Form.Group widths='equal'>
                <Field name='country' component={InputField}
                placeholder='Country (Optional)' />
              </Form.Group> */}
            </div>
            <div style={{background: '#e9f1ea', padding: '0 1em 1em 1em', ...(this.state.isRemark==1 ? {flex: 1} : {height: '10em', overflow: 'hidden'})}}>
              <Divider horizontal><Icon name={this.state.isRemark==1 ? 'arrow circle down' : 'arrow circle up'} color='red' style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setMode.bind(this)} /></Divider>
              <Header as='h4' color='blue' style={{marginTop: '0'}}>
                <Icon name='clone outline' style={{fontSize: '1em'}}/>
                <Header.Content><b>Feedbacks & Remarks</b></Header.Content>
              </Header>
              {this.state.isRemark && <>
                <Form.Group widths='equal'>
                  <Field name='remark' component={SelectField}
                  options={listRemark.options||[]}
                  label='Remark'
                  placeholder='Remarks' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='result' component={LabelNumberField}
                  label='Rp.'
                  caption= 'Opportunity Value'
                  placeholder='Result / Value' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='expired' component={LabelDatePickerField}
                  label='Expired'
                  // caption='Valid Until'
                  placeholder='Valid Date' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='reason' component={TextAreaField}
                  label='Description'
                  placeholder='Description / reason' />
                </Form.Group>
              </>}
            </div>
            <div style={{background: '#ccc', padding: '1em 1em', textAlign: 'right'}}>
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Visitor' : 'Insert Visitor') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <Button type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.visitorStore.getVisitor!=undefined && state.visitorStore.getVisitor.progress==1 ? state.visitorStore.getVisitor.data : {}),
      formValues: getFormValues('ChatboxCustomerForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      name: selector(state, 'name'),
      mobile: selector(state, 'mobile'),
      email: selector(state, 'email'),
    }),
    { load: getVisitor }
  )
  (reduxForm({
    form: 'ChatboxCustomerForm',	// a unique identifier for this form
  })(ChatboxCustomerForm)
  )
)
