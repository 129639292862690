import React, { Component } from 'react'
import { Progress, Container, Segment, Header, Card, Grid, Icon } from 'semantic-ui-react'

class SupervisorFooter extends Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }
  
  render() {
    const { screenHeight, screenWidth }= this.props;

    return (
      <div style={{background: '#fafafa', width: '100%', height: 60, padding: '1em 2em'}}>
        <Header as='h5'>
          <Header.Content>
            <b><i>Omni!</i></b> Customer Digital Experience  2.3.01
            <Header.Subheader><b>Pesonna Optima Jasa!</b> - Digital Communication Platform 2023</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    )
  }
}

export default SupervisorFooter