import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, formatDistance, formatRelative, } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { browseMessaging, statusMessaging } from "../../actions/stream/messagingAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    browseMessaging:(objParam) => dispatch(browseMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),
  }
}

class ChatviewConversation extends Component {
  constructor(props) {
    super(props)
    this.state= {
    }
  }

  render() {
    const { screenWidth, screenHeight }= this.props
    
    return (
      <div style={{display: 'flex', padding: '1em 0em', flexDirection: 'column', height: '100%'}}>
        <div>
          <div style={{textAlign: 'center'}}>
            <Header as='h4' icon style={{margin: '0'}}>
              <Icon name='comment alternate' circular inverted color='yellow' />
              <Header.Content>
                <b><i>Archive!</i></b> Conversation
                <Header.Subheader>Historical chat / conversation session</Header.Subheader>
              </Header.Content>
            </Header>
          </div>
          {/* <Divider horizontal>
            <Icon name='times' color='red' onClick={this.props.closeModal.bind(this)} style={{fontSize: '1.8em', cursor: 'pointer'}}/>
          </Divider> */}
        </div>
        <div id='wd_chat' className='chat' style={{margin: '2em 0 0 0', padding: '.5em 0', overflowY: 'auto', height: screenHeight-350, border: '1px solid #ddd', borderRadius: '1em', background: '#f5f5f5'}}>
          <div style={{padding: '.2em 0em', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            {
              this.props.params && this.props.params.conversations.map((item, idx)=>{
                return(
                  <div key={idx} style={{margin: '.1em .3em', textAlign: item.sender=='cust' ? 'left' : 'right'}}>
                    <div style={{background: item.sender=='cust' ? '#ffebe9' : '#d7fffe', float: item.sender=='cust' ? 'left' : 'right', padding: item.sender=='cust' ? '.5em 1em .2em .5em' : '.5em .5em .2em 1em', margin: item.sender=='cust' ? '0 2em 0 0' : '0 0 0 2em', borderRadius: item.sender=='cust' ? '1em 2.5em 2.5em 0em' : '2.5em 1em 0em 2.5em'}}>
                      <Header as='h5' style={{margin: '0', fontSize: '1em'}}>
                        <Header.Content style={{color: '#444', fontSize: '11pt', overflowWrap: 'anywhere'}}>
                          <Header.Subheader style={{fontSize: '.8em', borderBottom: '1px solid rgba(100, 100, 100, .5)'}}> ({idx+1}) {item.sender=='fadfa agent' ? this.props.params.target.name || 'Guest' : ''} <b style={{color: '#900'}}>{item.stamp && format(new Date(item.stamp), 'HH:mm')}</b>{item.stamp && format(new Date(item.stamp), ', dd/MMM yy')}</Header.Subheader>
                          {item.message}
                          {item.sender=='agent' &&
                          <Header.Subheader style={{margin: '0 -.3em', fontSize: '.8em'}}>
                            <Icon 
                            name={(this.props.params.status<0 && 'times') || (this.props.params.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'eye') || (this.props.params.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || (this.props.params.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'check square') || 'ellipsis horizontal'} 
                            color={(this.props.params.status<0 && 'red') || (this.props.params.readStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'brown') || (this.props.params.deliveredStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'blue') || (this.props.params.sentStamp_>=format(new Date(item.stamp), 'yyyy-MM-dd HH:mm:ss') && 'grey') || 'black'}
                            style={{margin: '0', padding: '0'}}/>
                            </Header.Subheader>}
                        </Header.Content>
                      </Header>
                    </div>
                  </div>
                )
              })
            }
            <div style={{flex: '1', textAlign: 'right'}} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatviewConversation)
