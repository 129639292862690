import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import { Accordion, Tab, Input, Card, Form, Select, Radio, Label, Container, Divider, Dropdown, Grid, Header, Image, List, Menu, Segment, Button, Icon, Table, Popup } from 'semantic-ui-react'

import { whatsappOptions, resultOptions }  from '../common/StaticMasterData'
import DismissableMessage from '../common/DismissableMessage'
import MenuTabular from '../common/MenuTabular'

import { SubmissionError, Field, reduxForm, change, formValueSelector } from 'redux-form'
import { ImageUploadField, AudioUploadField, InputField, LabelInputField, CheckboxField, SelectField, RadioField, TextAreaField, WysiwygField, NumberField, SliderField } from '../validation/componentrsui'
import { required, phoneNumber, number, minValue, email } from '../validation/validation'

import { connect } from "react-redux"
import { listParams } from "../../actions/halo/masterAction"
import { resetForm, echoWhatsapp} from "../../actions/auth/authAction"
import { resetSetting, getSetting, updateSetting } from "../../actions/halo/settingAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ApiForm')

const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    masterStore: state.masterStore,
    settingStore: state.settingStore,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    echoWhatsapp:(objParam) => dispatch(echoWhatsapp(objParam)),
    resetForm:(objParam) => dispatch(resetForm(objParam)),

    listParams:(objParam) => dispatch(listParams(objParam)),

    resetSetting:(objParam) => dispatch(resetSetting(objParam)),
    getSetting:(objParam) => dispatch(getSetting(objParam)),
    updateSetting:(objParam) => dispatch(updateSetting(objParam)),
  }
};


class ApiForm extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      tabChannel: 7,
      tabReport: 0,
      tabModule: 0,
      settingId: 1,

      // showModule: 0,
      showSending: 0,
      showLogger: 0,
      showApi: 1,
    }
  }

  componentDidMount() {
    //console.log('!!! DID MOUNT PROPS: ', this.props)
    this.props.resetSetting();
    this.props.getSetting({settingId: this.props.settingId})

    this.props.listParams({settingId: this.props.settingId})
  }

  componentDidUpdate(prevProps) {
    const { settingId }= this.state
    const { progress }= (this.props.authStore.echoWhatsapp!=undefined ? this.props.authStore.echoWhatsapp : {})
    
    if (progress==1 || progress==2) {
      setTimeout(()=>{
        this.props.resetForm('echoWhatsapp')
      }, 5000)
    }
    
    if (settingId!=this.props.settingId) {
      this.setState({
        settingId: this.props.settingId
      })
      // console.log(data.settingId, this.props.settingId)
      this.props.getSetting({settingId: this.props.settingId})
      this.props.listParams({settingId: this.props.settingId})
    }
    
  }

  resetForm() {
    const { reset, mode } = this.props
    reset();
  }

  onSubmit(values) {
    const { mode} = this.props
    
    if(mode==2) { //UPDATE
      this.props.echoWhatsapp({
        boxType: values['boxType'],
        boxOriginating: values['boxOriginating'],
        boxTerminating: values['boxTerminating'],
        boxContent: values['boxContent'],

        boxBrand: values['boxBrand'],
        boxDescript: values['boxDescript'],
        boxOrganization: values['boxOrganization'],
        boxCity: values['boxCity'],
        boxNumbers: values['boxNumbers'],
      })
    }
  }

  showMode(v) {
    if (v=='sending') {
      this.props.dispatch(change('ApiForm', 'boxOriginating', this.props.waMobile_1))
      this.props.dispatch(change('ApiForm', 'boxTerminating', this.props.waMobile_1))
      this.setState({
        showSending: this.state.showSending==1 ? 0 : 1,
        showModule: 0,
        showApi: 0,
        showLogger: 0,
      })
    } else if (v=='api') {
      this.setState({
        showSending: 0,
        showModule: 0,
        showApi: this.state.showApi==1 ? 0 : 1,
        showLogger: 0,
      })
    } else if (v=='logger') {
      this.setState({
        showSending: 0,
        showModule: 0,
        showApi: 0,
        showLogger: this.state.showLogger==1 ? 0 : 1,
      })
    }
  }

  render() {
    const { tabChannel, tabReport, tabModule, showModule, showSending, showApi, showLogger }= this.state
    const { error, handleSubmit, pristine, reset, submitting, group, mode, screenWidth, screenHeight }=this.props
    
    const { progress, code, text }= (this.props.authStore.echoWhatsapp!=undefined ? this.props.authStore.echoWhatsapp : {})

    const { svcCampaigns, svcRatios, svcChannels }= this.props.masterStore.listParams && this.props.masterStore.listParams.data ? this.props.masterStore.listParams.data : {}

    return(
      <div style={{marginTop: '-1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showApi==1 && 'blue') || 'black'}>
                <Header.Content>
                  Callback Event URL
                  <Header.Subheader>Capture message status (sent, delivered, read) and incoming message notification</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showApi==1 && 'toggle on') || 'toggle off'} color={(showApi==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'api')} />
            </div>
          </div>
          <Divider />
          {showApi==1 && <>
            {(this.props.apiKey!=null) && 
            <>
              <Segment>
                <Header as='h5'>
                  <Header.Content>Callback Param</Header.Content>
                </Header>
                <Form.Group widths='16'>
                  <Field name='apiKey' component={InputField}
                  width={8}
                  readOnly={this.props.group!='admin'}
                  label='Callback Token' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='apiEndpoint' component={InputField}
                  readOnly
                  label= 'API Callback / Endpoint' 
                  placeholder= 'Input Your Callback Event URL, it will be hit by Pesonna Optima Jasa! for notification or message delivery status' />
                </Form.Group>
              </Segment>
            </>}
            {(this.props.apiKey==null) && 
            <Segment>
              <Header as='h3' color='red'>
                <Icon name='warning sign'/>
                <Header.Content>API Connect Not Available!
                  <Header.Subheader>Whether your Admin is not allowed or configuration has not been granted!</Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            }
          </>}

          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showSending==1 && 'blue') || 'black'}>
                <Header.Content>
                  Sending Message API
                  <Header.Subheader>Sending message through realiable API for marketing / promotion and OTP</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showSending==1 && 'toggle on') || 'toggle off'} color={(showSending==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'sending')} />
            </div>
          </div>
          <Divider />
          {showSending==1 && <>
            <Segment>
              <Header as='h5'>
                Sending Message
              </Header>
              <Form.Group widths='16'>
                <Field name='boxType' component={SelectField}
                width={4}
                label='Message Type'
                options={whatsappOptions}
                placeholder='Message Type' />
                <Field name='boxOriginating' component={InputField}
                width={6}
                readOnly
                label='Originating'
                placeholder='Sender / Originating' />
                <Field name='boxTerminating' component={InputField}
                width={6}
                label='Terminating'
                placeholder='Destination / Terminating' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='boxContent' component={TextAreaField}
                rows={5}
                label='Content / Message'
                placeholder='Message content / information' />
              </Form.Group>
              
              <Header as='h5'>
                Contact Card / VCARD
              </Header>
              <Form.Group widths='16'>
                <Field name='boxBrand' component={InputField}
                width={4}
                label='Brand'
                placeholder='Brand' />
                <Field name='boxOrganization' component={InputField}
                width={6}
                label='Organization'
                placeholder='Organization' />
                <Field name='boxCity' component={InputField}
                width={6}
                label='City'
                placeholder='City' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='boxDescript' component={InputField}
                width={16}
                label='Description'
                placeholder='Description' />
              </Form.Group>
              <Form.Group widths='16'>
                <Field name='boxNumbers' component={InputField}
                width={16}
                label='Mobile Numbers'
                placeholder='WA account mobile numbers 62XXX1, 62XXX2' />
              </Form.Group>

              {(progress==1 || progress==2)  && <DismissableMessage style={{margin: '2em 0 0 0'}} mode={this.props.mode} header={'Sending Message' + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              <div style={{display: 'flex', marginTop: '1em'}}>
                <div style={{flex: '1'}}>
                  <Button type='reset' size='small' content=' RESET ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
                </div>
                <div>
                  <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} SEND</Button>
                </div>
              </div>
            </Segment>
          </>}

          <div style={{display: 'flex', marginBottom: '1em'}}>
            <div style={{flex: '1'}}>
              <Header as='h4' style={{fontWeight: 'bold', marginTop: '1em'}} color={(showLogger==1 && 'blue') || 'black'}>
                <Header.Content>
                  Api Transaction/ Session Logger
                  <Header.Subheader>List available API transaction logger, for current session only!</Header.Subheader>
                </Header.Content>
              </Header>
            </div>
            <div style={{marginTop: '1.5em', marginLeft: '1em'}}>
              <Icon name={(showLogger==1 && 'toggle on') || 'toggle off'} color={(showLogger==1 && 'blue') || 'grey'} style={{fontSize: '1.5em', marginTop:'.3em', cursor: 'pointer', transform: 'rotate(90deg)'}} onClick={this.showMode.bind(this, 'logger')} />
            </div>
          </div>
          <Divider />
          {showLogger==1 && <>
            <Segment>
              LOGGER SHOWN HERE
              <Divider />
              LOGGER SHOWN HERE
            </Segment>
          </>}
        </Form >
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.settingStore.getSetting!=undefined && state.settingStore.getSetting.progress==1 ? {...state.settingStore.getSetting.data, boxType: 'otp'} : {}),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      userId: selector(state, 'userId'),
      apiKey: selector(state, 'apiKey'),
      apiEndpoint: selector(state, 'apiEndpoint'),
      isPopup: selector(state, 'isPopup'),
      isCallback: selector(state, 'isCallback'),
      isIntegrated: selector(state, 'isIntegrated'),
      
      collectionStageQty: selector(state, 'collectionStageQty'),
      restructuringStageQty: selector(state, 'restructuringStageQty'),
      telesaleStageQty: selector(state, 'telesaleStageQty'),
      verificationStageQty: selector(state, 'verificationStageQty'),
      reportCdrPeriod: selector(state, 'reportCdrPeriod'),
      reportMdrPeriod: selector(state, 'reportMdrPeriod'),
      reportTrxPeriod: selector(state, 'reportTrxPeriod'),
      smsGateway: selector(state, 'smsGateway'),
      waGateway: selector(state, 'waGateway'),
      mailGateway: selector(state, 'mailGateway'),

      waMobile_1: selector(state, 'waMobile_1'),
      boxOriginating: selector(state, 'boxOriginating'),
      boxTerminating: selector(state, 'boxTerminating'),
      boxContent: selector(state, 'boxContent'),
    }),
    { load: getSetting }
  )
  (reduxForm({
    form: 'ApiForm',	// a unique identifier for this form
  })(ApiForm)
  )
)
