import React,{Component} from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';


import DashboardBody from './DashboardBody';
import WizardBody from './WizardBody';
import MessagingInboundBody from './MessagingInboundBody';
import MessagingOutboundBody from './MessagingOutboundBody';
import CampaignBody from './CampaignBody';

import OnboardingBody from './OnboardingBody';

import ImpressionBody from './ImpressionBody';
import MessagingBody from './MessagingBody';
import TargetBody from './TargetBody';

import ProfileBody from './ProfileBody';
import MessageBody from './MessageBody';
import BillingBody from './BillingBody';
import SettingBody from './SettingBody';
import ApiBody from './ApiBody';
import SenderBody from './SenderBody';

import DeductBody from './DeductBody';
import TopupBody from './TopupBody';
import RecordBody from './RecordBody';

import QuickchatBody from './QuickchatBody';
import ChatboxBody from './ChatboxBody';

import InformationBody from './InformationBody';


import { connect } from "react-redux"
import { checkToken } from "../../actions/auth/authAction"
import MenuLeft from '../../libraries/whatsapp/MenuLeft';
import InteractionBody from './InteractionBody';
import BroadcastBody from './BroadcastBody';
import ContactBody from './ContactBody';
import WaserverBody from './WaserverBody';
import ConversationBody from './ConversationBody';
import ChatviewBody from './ChatviewBody';
import AccountBody from './AccountBody';
import WorkgroupBody from '../realm/halo/WorkgroupBody';
import ReportingBody from '../realm/halo/ReportingBody';
import AudittrailBody from '../realm/system/AudittrailBody';
import ScriptBody from './ScriptBody';
import PerformanceBody from './PerformanceBody';
import ChatBody from './ChatBody';


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkToken:(objParam) => dispatch(checkToken(objParam)),
  }
}

class WhatsappBody extends Component {
  constructor(props){
  	super(props)
  	this.state = {
      mode: 'interaction',
      modal: 'authentication',
      
      onboarding: false,
      topupalert: false,
    }
  }

  componentDidMount() {
    this.props.checkToken();
  }

  componentDidUpdate() {
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}
    data && code=='00' && data.groupId=='Client' && data.isConfigured=='0' && this.state.onboarding==false && this.setState({
      onboarding: true,
    })

    data && code=='00' && data.billingType=='prepaid' && parseFloat(data.depositCredit)<=0 && this.state.topupalert==false && this.setState({
      topupalert: true,
    })
  }

  closeModal(params) {
    this.props.closeModal(params)
  }
  
  openModal(value, params) {
    this.props.openModal(value, params)
  }

  openWindow(mode) {
    this.props.openWindow(mode)
  }

  render() {
    const { data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    const { onboarding, topupalert }= this.state
    const { mode, module, screenWidth, screenHeight, modal }= this.props
    
    // console.log('CHECKTOKEN', code, data)
    if (data && data.groupId=='Client') {
      return (
        <div style={{display: 'flex'}}>
          <div style={{background: '#9fcfcf', width: '3.5em', paddingTop: '6em', textAlign: 'center'}}>
            <MenuLeft openModal={this.props.openModal} openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} groupId={data && data.groupId} />
          </div>
          <div style={{flex: 1, margin: '3.3em 0 0 0'}}>
            {modal==='wizard' && <ModalContainer size='small' content={<WizardBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='campaign' && <ModalContainer size='small' content={<CampaignBody mode={2} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='inbound' && <ModalContainer size='small' content={<MessagingInboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='outbound' && <ModalContainer size='small' content={<MessagingOutboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='impression' && <ModalContainer size='small' content={<ImpressionBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='messaging' && <ModalContainer size='small' content={<MessagingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='target' && <ModalContainer size='small' content={<TargetBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.props.closeModal} /> }
            {modal==='billing' && <ModalContainer size='small' content={<BillingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='api' && <ModalContainer size='small' content={<ApiBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='sender' && <ModalContainer size='small' content={<SenderBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
                  
            {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='information' && <ModalContainer dialog size='tiny' content={<InformationBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='conversation' && <ModalContainer size='small' content={<QuickchatBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal && modal.substring(0,7)==='chatbox' && <ModalContainer size='small' content={<ChatboxBody mode={modal && modal.substring(8)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            
            {/* {mode==='authentication' && <AuthenticateBody />} */}
            {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
  
            {onboarding && <OnboardingBody dialog />}
            {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='interaction' && <ChatBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {/* {mode==='interaction' && <InteractionBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>} */}
            {mode==='broadcast' && <BroadcastBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='conversation' && <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
  
            {modal==='contact' && <ModalContainer size='small' content={<ContactBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='waserver' && <ModalContainer size='small' content={<WaserverBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='chatview' && <ModalContainer size='mini' content={<ChatviewBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='account' && <ModalContainer size='small' content={<AccountBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {/* REFER TO HALOPAGI */}
            {modal==='script' && <ModalContainer size='small' content={<ScriptBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='workgroup' && <ModalContainer size='large' content={<WorkgroupBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='reporting' && <ModalContainer size='small' content={<ReportingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='audittrail' && <ModalContainer size='small' content={<AudittrailBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }

            {modal==='performance_campaign' && <ModalContainer size='large' content={<PerformanceBody campaign screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='performance_message' && <ModalContainer size='large' content={<PerformanceBody messaging screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='performance_ticket' && <ModalContainer size='large' content={<PerformanceBody ticket screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
  
          </div>
        </div>
      )
    } else if (data && data.groupId=='Supervisor') {
      return (
        <div style={{display: 'flex'}}>
          <div style={{background: '#9fcfcf', width: '3.5em', paddingTop: '6em', textAlign: 'center'}}>
            <MenuLeft openModal={this.props.openModal} openWindow={this.props.openWindow} mode={this.props.mode} screenHeight={screenHeight} screenWidth={screenWidth} module={module} groupId={data && data.groupId} />
          </div>
          <div style={{flex: 1, margin: '3.3em 0 0 0'}}>
            {modal==='wizard' && <ModalContainer size='small' content={<WizardBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='campaign' && <ModalContainer size='small' content={<CampaignBody mode={2} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='inbound' && <ModalContainer size='small' content={<MessagingInboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='outbound' && <ModalContainer size='small' content={<MessagingOutboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='impression' && <ModalContainer size='small' content={<ImpressionBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='messaging' && <ModalContainer size='small' content={<MessagingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='target' && <ModalContainer size='small' content={<TargetBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.props.closeModal} /> }
            {modal==='billing' && <ModalContainer size='small' content={<BillingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='api' && <ModalContainer size='small' content={<ApiBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='sender' && <ModalContainer size='small' content={<SenderBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
                  
            {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='information' && <ModalContainer dialog size='tiny' content={<InformationBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='conversation' && <ModalContainer size='small' content={<QuickchatBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal && modal.substring(0,7)==='chatbox' && <ModalContainer size='small' content={<ChatboxBody mode={modal && modal.substring(8)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            
            {/* {mode==='authentication' && <AuthenticateBody />} */}
            {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
  
            {onboarding && <OnboardingBody dialog />}
            {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='interaction' && <InteractionBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='broadcast' && <BroadcastBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='conversation' && <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
  
            {modal==='contact' && <ModalContainer size='small' content={<ContactBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='waserver' && <ModalContainer size='small' content={<WaserverBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='chatview' && <ModalContainer size='mini' content={<ChatviewBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='account' && <ModalContainer size='small' content={<AccountBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {/* REFER TO HALOPAGI */}
            {modal==='workgroup' && <ModalContainer size='large' content={<WorkgroupBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='reporting' && <ModalContainer size='small' content={<ReportingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='audittrail' && <ModalContainer size='small' content={<AudittrailBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
  
          </div>
        </div>
      )
    } else if (data && data.groupId=='Controller') {
      return (
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, margin: '3.3em 0 0 0'}}>
            {modal==='wizard' && <ModalContainer size='small' content={<WizardBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='campaign' && <ModalContainer size='small' content={<CampaignBody mode={2} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='inbound' && <ModalContainer size='small' content={<MessagingInboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='outbound' && <ModalContainer size='small' content={<MessagingOutboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='impression' && <ModalContainer size='small' content={<ImpressionBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='messaging' && <ModalContainer size='small' content={<MessagingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='target' && <ModalContainer size='small' content={<TargetBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.props.closeModal} /> }
            {modal==='billing' && <ModalContainer size='small' content={<BillingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='api' && <ModalContainer size='small' content={<ApiBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='sender' && <ModalContainer size='small' content={<SenderBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
                  
            {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='information' && <ModalContainer dialog size='tiny' content={<InformationBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='conversation' && <ModalContainer size='small' content={<QuickchatBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal && modal.substring(0,7)==='chatbox' && <ModalContainer size='small' content={<ChatboxBody mode={modal && modal.substring(8)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            
            {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
  
            <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>
            {modal==='chatview' && <ModalContainer size='mini' content={<ChatviewBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
          </div>
        </div>
      )
    } else if (data && data.groupId=='Agent') {
      return (
        <div style={{display: 'flex'}}>
          <div style={{flex: 1, margin: '3.3em 0 0 0'}}>
            {modal==='wizard' && <ModalContainer size='small' content={<WizardBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='campaign' && <ModalContainer size='small' content={<CampaignBody mode={2} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='inbound' && <ModalContainer size='small' content={<MessagingInboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='outbound' && <ModalContainer size='small' content={<MessagingOutboundBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='impression' && <ModalContainer size='small' content={<ImpressionBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='messaging' && <ModalContainer size='small' content={<MessagingBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal==='target' && <ModalContainer size='small' content={<TargetBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
  
            {modal==='profile' && <ModalContainer size='small' content={<ProfileBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='message' && <ModalContainer size='tiny' content={<MessageBody screenWidth={screenWidth} screenHeight={screenHeight} />} closeModal={this.props.closeModal} /> }
            {modal==='billing' && <ModalContainer size='small' content={<BillingBody screenWidth={screenWidth} screenHeight={screenHeight} openWindow={this.props.openWindow} openModal={this.props.openModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='setting' && <ModalContainer size='small' content={<SettingBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='api' && <ModalContainer size='small' content={<ApiBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='sender' && <ModalContainer size='small' content={<SenderBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
                  
            {modal==='deduct' && <ModalContainer size='small' content={<DeductBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='topup' && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            {modal==='record' && <ModalContainer size='small' content={<RecordBody screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
  
            {modal==='information' && <ModalContainer dialog size='tiny' content={<InformationBody screenWidth={screenWidth} screenHeight={screenHeight}  closeModal={this.props.closeModal} />} closeModal={this.props.closeModal} /> }
            
            {modal==='conversation' && <ModalContainer size='small' content={<QuickchatBody screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            {modal && modal.substring(0,7)==='chatbox' && <ModalContainer size='small' content={<ChatboxBody mode={modal && modal.substring(8)} screenWidth={screenWidth} screenHeight={screenHeight}  openModal={this.props.openModal} closeModal={this.props.closeModal} params={this.props.params} />} closeModal={this.props.closeModal} /> }
            
            {/* {mode==='authentication' && <AuthenticateBody />} */}
            {!onboarding && topupalert && <ModalContainer size='small' content={<TopupBody screenWidth={screenWidth} />} closeModal={this.props.closeModal} /> }
  
            {onboarding && <OnboardingBody dialog />}
            {mode==='dashboard' && <DashboardBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='interaction' && <InteractionBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='broadcast' && <BroadcastBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
            {mode==='conversation' && <ConversationBody openWindow={this.props.openWindow} screenWidth={screenWidth} screenHeight={screenHeight} module={module} openModal={this.props.openModal} closeModal={this.props.closeModal} checkToken={data} modal={modal}/>}
          </div>
        </div>
      )
    } else {
      return (<div style={{padding: '6em 4.2em', minHeight: screenHeight}}>
        <Header as='h5'>
          <Icon name='warning circle' color='red' />
          <Header.Content style={{fontSize: '1.3em'}}>
            <b>Loading ....</b>
          </Header.Content>
        </Header>
      </div>
      )
    }

  }
}

// export default WhatsappBody
export default connect(mapStateToProps, mapDispatchToProps)(WhatsappBody)