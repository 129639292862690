import React, { Component } from 'react'
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon, Table } from 'semantic-ui-react'

import numeral from "numeral"
import { format, parse } from "date-fns"

import DismissableMessage from '../common/DismissableMessage'
import {genderOptions, maritalOptions}  from '../common/StaticMasterData'

import { SubmissionError, Field, reduxForm, getFormValues, change, formValueSelector } from 'redux-form'
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField, TextAreaField, LabelNumberField, LabelDatePickerField } from '../validation/componentrsui'
import { required, confirmedPassword, phoneNumber, number, minValue, email, phoneNumberID } from '../validation/validation'

import { connect } from "react-redux"
import { resetTarget, getTarget,updateTarget, createTarget } from "../../actions/stream/targetAction"
import { contactOmniphone,remarkOmniphone, resetOmniphone } from "../../actions/stream/omniphoneAction"
import { listRemark, listProduct } from "../../actions/halo/masterAction"

//CONNECT REDUX STORE
const selector = formValueSelector('ChatboxTargetForm')

const mapStateToProps = (state, props) => {
  return {
    masterStore: state.masterStore,

    targetStore: state.targetStore,
    omniphoneStore: state.omniphoneStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    listRemark:(objParam) => dispatch(listRemark(objParam)),
    listProduct:(objParam) => dispatch(listProduct(objParam)),

    resetTarget:(objParam) => dispatch(resetTarget(objParam)),
    getTarget:(objParam) => dispatch(getTarget(objParam)),
    updateTarget:(objParam) => dispatch(updateTarget(objParam)),

    resetOmniphone:(objParam) => dispatch(resetOmniphone(objParam)),
    remarkOmniphone:(objParam) => dispatch(remarkOmniphone(objParam)),
    contactOmniphone:(objParam) => dispatch(contactOmniphone(objParam)),
  }
}

class ChatboxTargetForm extends Component {
  constructor(props) {
    super(props)
    this.state= {
      isRemark: true,
      lastTarget: null,
    }
  }

  componentDidMount(){
    const { status, conversation }= this.props;
    // console.log('!!! CONVERSATION', conversation)

    this.props.resetTarget(this.props.mode)
    this.props.getTarget({targetId: conversation.targetId})

    if (conversation) {
      // console.log('DIDMOUNT', status, 'visitorId: ', conversation.visitorId, 'targetId: ', conversation.targetId);

      this.props.listRemark({campaignId: conversation.campaignId});
      this.props.listProduct();

      this.props.dispatch(change('ChatboxTargetForm', 'remark', conversation.remark));
      this.props.dispatch(change('ChatboxTargetForm', 'progress', conversation.progress));

      if (conversation.remarks) {
        this.props.dispatch(change('ChatboxTargetForm', 'note', conversation.remarks.note));
        this.props.dispatch(change('ChatboxTargetForm', 'promisedDate', conversation.remarks.promisedDate));
        this.props.dispatch(change('ChatboxTargetForm', 'paidDate', conversation.remarks.paidDate));
        this.props.dispatch(change('ChatboxTargetForm', 'paymentChannel', conversation.remarks.paymentChannel));
        this.props.dispatch(change('ChatboxTargetForm', 'adjustmentAmount', conversation.remarks.adjustmentAmount));
        this.props.dispatch(change('ChatboxTargetForm', 'adjustmentDuration', conversation.remarks.adjustmentDuration));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { progress, code, text }= this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {};
    
    if (progress==1 && code=='00') {
      setTimeout(()=>{
        this.props.resetOmniphone();
        this.props.closeModal();
      }, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { status, conversation }= this.props;

    // const { media } = this.props
    this.props.updateTarget({
      targetId: values.targetId,
      name: values.name,
      email: values.email,
      gender: values.gender,
      birthDate: values.birthDate,
      descript: values.descript,
      address: values.address,
      city: values.city,
      province: values.province,
    })
    
    this.props.remarkOmniphone({
      targetId: values.targetId,
      messagingId: conversation && conversation.messagingId,
      campaignType: conversation && conversation.campaign && conversation.campaign.campaignType,

      remark: values.remark,
      progress: values.progress,
      note: values.note || (conversation && conversation.remarks && conversation.remarks.note),

      promisedDate: values.promisedDate || (conversation && conversation.remarks && conversation.remarks.promisedDate),
      paidDate: values.paidDate || (conversation && conversation.remarks && conversation.remarks.paidDate),
      paymentChannel: values.paymentChannel || (conversation && conversation.remarks && conversation.remarks.paymentChannel),
      adjustmentAmount: values.adjustmentAmount || (conversation && conversation.remarks && conversation.remarks.adjustmentAmount),
      adjustmentDuration: values.adjustmentDuration || (conversation && conversation.remarks && conversation.remarks.adjustmentDuration),
      value: values.value || (conversation && conversation.remarks && conversation.remarks.value),
      
      voices: null, appointments: null })
  }

  setMode() {
    this.setState({isRemark: !this.state.isRemark})
  }

  render() {
    const { tab }= this.state
    const { error, handleSubmit, pristine, reset, submitting, mode, style, conversation } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {}) : (this.props.targetStore.createTarget!=undefined ? this.props.targetStore.createTarget : {}))
    const { data }= this.props.targetStore.getTarget!=undefined ? this.props.targetStore.getTarget : {}

    const listRemark= (this.props.masterStore.listRemark!=undefined && this.props.masterStore.listRemark.progress=='1' ? this.props.masterStore.listRemark.data : {})
    
    return (
      <div style={{margin: '0 0em', height: '100%'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))} style={{height: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <div style={{...(!this.state.isRemark ? {flex: 1} : {overflow: 'hidden'}), padding: '1em 1em'}}>
              {/* <div style={{textAlign: 'center'}}>
                <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='small' circular style={{display: 'inline-block'}}/>
                <Icon name='user circle' color='yellow' style={{fontSize: '8em', margin: '0'}} />
                <Header as='h4'>
                  <Header.Content>
                    {this.props.params && ((this.props.params.target && this.props.params.target.name) || (this.props.params.customer && this.props.params.customer.name) || (this.props.params.target && this.props.params.target.name) || 'Guest / Target')}
                    <Header.Subheader>{this.props.params && ((this.props.params.target && 'Campaign target contact') || (this.props.params.customer && 'Customer data') || (this.props.params.target && 'Public target'))}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div> */}
              <div style={{textAlign: 'center', marginBottom: '2em'}}>
                <Header as='h3' icon>
                  <Icon name='warning' style={{fontSize: '.8em'}} circular inverted color='orange' />
                  <Header.Content>
                    Campaign's Target
                    <Header.Subheader>Personal data and remark/feedback update</Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider style={{margin: '.3em 0'}}/>
                <span>Ingestion is available from <b>Secured Local Relay API</b> for enhance security level, contact your Admin to connect.</span>
              </div>

              <Form.Group widths='equal'>
                <Field name='name' component={InputField}
                // label='Name'
                validate={required}
                placeholder='Full Name' />
              </Form.Group>
              <Form.Group widths='equal'>
                <Field name='email' component={InputField}
                // label='Email'
                validate={email}
                placeholder='Email (Optional)' />
              </Form.Group>
              {/* <Form.Group widths='equal'>
                <Field name='whatsapp' component={InputField}
                label='Whatsapp'
                validate={[required, phoneNumberID]}
                placeholder='Whatsapp' />
              </Form.Group> */}
              
              {!this.state.isRemark && <>
                <Header as='h5'>
                  <Icon name='id card outline' style={{fontSize: '1em'}}/>
                  <Header.Content>Personal</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='gender' component={SelectField}
                  options={genderOptions}
                  // label='Gender'
                  placeholder='L/P (Optional)' />
                  <Field name='birthDate' component={DatePickerField}
                  // label='Birth Date'
                  placeholder='(Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='keyword' component={TextAreaField}
                  // label='Description'
                  rows={2}
                  placeholder='Short description (Optional)' />
                </Form.Group>
              
                <Header as='h5'>
                  <Icon name='map marker alternate' style={{fontSize: '1em'}}/>
                  <Header.Content>Address</Header.Content>
                </Header>
                <Form.Group widths='equal'>
                  <Field name='address' component={InputField}
                  placeholder='Address (Optional)' />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Field name='city' component={InputField}
                  placeholder='City (Optional)' />
                  <Field name='province' component={InputField}
                  placeholder='Province (Optional)' />
                </Form.Group>
              </>}
              {/* <Form.Group widths='equal'>
                <Field name='country' component={InputField}
                placeholder='Country (Optional)' />
              </Form.Group> */}
            </div>
            <div style={{background: '#f1efe9', padding: '0 1em 1em 1em', ...(this.state.isRemark==1 ? {flex: 1} : {height: '10em', overflow: 'hidden'})}}>
              <Divider horizontal><Icon name={this.state.isRemark==1 ? 'arrow circle down' : 'arrow circle up'} color='red' style={{fontSize: '1.5em', cursor: 'pointer'}} onClick={this.setMode.bind(this)} /></Divider>
              <Header as='h5' color='red' style={{marginTop: '0'}}>
                <Icon name='clone outline' style={{fontSize: '1em'}}/>
                <Header.Content><b>Feedbacks & Remarks</b></Header.Content>
              </Header>
              {this.state.isRemark && <>
                <Form.Group widths='equal'>
                  <Field name='remark' component={SelectField}
                  options={listRemark.options || []}
                  label='Remark'
                  placeholder='Remarks' />
                </Form.Group>
                {conversation.campaign && (conversation.campaign.campaignType=='Collection' || conversation.campaign.campaignType=='Restructuring' || conversation.campaign.campaignType=='Verification') && <>
                  {this.props.remark=='Promised' && <Form.Group widths='equal'>
                    <Field name='promisedDate' component={DatePickerField}
                    width={4}
                    label= 'Promised Date'
                    placeholder='Promised Date' />
                  </Form.Group>}
                  {this.props.remark=='Paid' && <Form.Group widths='equal'>
                    <Field name='paidDate' component={DatePickerField}
                    width={4}
                    label= 'Payment Date'
                    placeholder='Payment Date' />
                  </Form.Group>}
                </>}
                {conversation.campaign && conversation.campaign.campaignType=='Telesales' && <>
                  <Form.Group widths='equal'>
                    <Field name='productId' component={SelectField}
                    width={8}
                    options={listProduct||[]}
                    label='Offered Product / Service'
                    placeholder='Offered Product / Service' />
                  </Form.Group>
                </>}
                <Form.Group widths='equal'>
                  <Field name='note' component={TextAreaField}
                  label='Note / Information'
                  placeholder='Additional information' />
                </Form.Group>
              </>}
            </div>
            <div style={{background: '#ccc', padding: '1em 1em', textAlign: 'right'}}>
              {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Target' : 'Insert Target') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
              
              <Button type='reset' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.targetStore.getTarget!=undefined && state.targetStore.getTarget.progress==1 ? state.targetStore.getTarget.data : {}),
      formValues: getFormValues('ChatboxTargetForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,
      name: selector(state, 'name'),
      mobile: selector(state, 'mobile'),
      email: selector(state, 'email'),

      remark: selector(state, 'remark'),
    }),
    { load: getTarget }
  )
  (reduxForm({
    form: 'ChatboxTargetForm',	// a unique identifier for this form
  })(ChatboxTargetForm)
  )
)
