import React,{Component} from 'react';
import { Divider, Popup, Button, Icon, Header, Image, Segment, Feed, Grid } from 'semantic-ui-react'


import { connect } from "react-redux"
import { statCampaign } from "../../../actions/halo/statisticAction"
import PieRechart from '../../chart/PieRechart';

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    statisticStore: state.statisticStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    statCampaign:(objParam) => dispatch(statCampaign(objParam)),
  }
}

class ImpressionWidget extends Component {
  constructor(props){
  	super(props);
  	this.state = {};
  }

  componentDidMount() {
    this.props.statCampaign() 
  }
  
  render() {
    const { screenWidth,  screenHeight, height}= this.props
    const { data, search, paging }= (this.props.statisticStore.statCampaign!==undefined ? this.props.statisticStore.statCampaign : {})
    
    const pieData= [];
    data && Object.entries(data).forEach(([key, value]) => {
      pieData.push({name: key, value: parseInt(value || 0)});
    })
    return (
      <>
      <Segment attached='top' style={{padding: '1em .5em'}}>
        <Header as='h5'>
          <Icon name='chart bar' style={{fontSize: '1em'}} />
          <Header.Content>
            Campaign Impression
            <Header.Subheader>Impression based on Sent, Delivered, Read</Header.Subheader>
          </Header.Content>
        </Header>
      </Segment>
      <Segment attached='bottom' style={{padding: '1em', minHeight: height}}>
        
      {data!=undefined &&
            <PieRechart data={pieData} width={380} height={height-40} mode='active' fill='#555'/>}
      </Segment>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpressionWidget)
