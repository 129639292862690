import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Pagination,
  Feed,
  Popup,
} from 'semantic-ui-react'

import ReactAudioPlayer from 'react-audio-player';
import numeral from "numeral"
import { format, parse, formatDistance, formatRelative, } from "date-fns"
import { messagingStatus } from "../../libraries/common/StaticMasterData"


import { connect } from "react-redux"
import { outboundMessaging, statusMessaging } from "../../actions/stream/messagingAction"
import { socketStatus, socketWhatsappStatistic } from "../../actions/socket/socketAction"

//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    socketStore: state.socketStore,
    messagingStore: state.messagingStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    outboundMessaging:(objParam) => dispatch(outboundMessaging(objParam)),
    statusMessaging:(objParam) => dispatch(statusMessaging(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappStatistic:(objParam) => dispatch(socketWhatsappStatistic(objParam)),
  }
}

class MessagingOutboundRecent extends Component {
  constructor(props) {
    super(props)
    this.state= {
      content: false,
      expand: false,
      recent: null,
      messages: new Map(),
      size: 50,
    }
  }

  componentDidUpdate(prevProps) {
    const {messages}= this.state;
    const {outboundMessage}= (this.props.socketStore.socketWhatsappStatistic!==undefined ? this.props.socketStore.socketWhatsappStatistic.data.payload : {})

    if (outboundMessage && (!this.state.recent || this.state.recent!=outboundMessage.recent)) {
      
      outboundMessage && outboundMessage.messages && outboundMessage.messages.map(item=>{
        messages.get(item.messagingId) && messages.delete(item.messagingId);
        messages.set(item.messagingId, item);
      })

      if (messages.size>this.state.size) {
        [...messages.keys()].map((key,i)=>{
          if (i<(messages.size-this.state.size)) {
            messages.get(key) && messages.delete(key);
          }
        })
      }
      this.setState({
        recent: outboundMessage.recent,
        messages: messages,
      })
    }
    // console.log(outboundMessage)
  }

  render() {
    //console.log(this.props.messagingStore.outboundMessaging)
    const { expand, content, messages }= this.state
    const { screenWidth, screenHeight }= this.props
    
    var statusMesg= 0;

    return (
      <div style={{display: 'flex', padding: '0 0 0 0', flexDirection: 'column'}}>
        <div style={{flex: '1', padding: '0'}}>
          <Feed>
          { messages && messages.size==0 && <div style={{padding: '.5em 0'}}><Label style={{fontSize: '1em', padding: '.5em 1em', borderRadius: '1em'}}><Icon name='warning sign' /> No data available</Label></div>}
              { messages ? [...messages.keys()].map((key, i)=>{
                  const item= messages.get(key);
                  if (item.conversations[item.conversations.length-1] && item.conversations[item.conversations.length-1].message) {
                    const mesg= item.conversations[item.conversations.length-1];
                    if (mesg.sender!='cust') {
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.sentStamp_) {
                        statusMesg= 1; //sent
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.deliveredStamp_) {
                        statusMesg= 2; //delivered
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.readStamp_) {
                        statusMesg= 3; //read
                      }
                      if (format(new Date(mesg.stamp), 'yyyy-MM-dd HH:mm:ss')<=item.clickStamp_) {
                        statusMesg= 4; //clicked
                      }
                    } else {
                      if (item.receivedStamp_) {
                        statusMesg= 5; //unread
                      }
                    }
                  }

                  return(
                    <Feed.Event key={i} style={{background: i%2==0 ? '#fffff' : '#fcfcfc', borderTop:i!=0 ? '1px solid #fff' : 'none', borderBottom:i!=(messages.size-1) ? '1px solid rgba(100, 100, 100, .1)' : 'none'}}>
                      <Feed.Label style={{marginTop: '1em'}} image='https://cdn-icons-png.flaticon.com/512/7070/7070249.png' />
                      <Feed.Content style={{overflow: 'hidden'}}>
                        <Feed.Summary style={{marginBottom: '.5em'}}>
                          <Label as='a' style={{textTransform: 'capitalize', color: '#1d7fff', fontSize: '1.1em', padding: '.3em .5em', borderRadius: '1em'}} onClick={this.props.openModal.bind(this, 'chatbox-outbound', item)}>
                            {(item.target && item.target.name) || (item.customer && item.customer.name) || (item.visitor && item.visitor.name) || (item.contact && item.contact.name) || (item.terminating && (item.terminating.substring(0,5).replace('62', '0') +'XXXX'+ item.terminating.substring(item.terminating.length-4,item.terminating.length))) || 'Guest / Visitor'}
                          </Label>
                          <div style={{fontSize: '.8em', paddingLeft: '.7em', fontWeight: '400'}}>
                            {(item.target && 'Campaign Target') || (item.customer && 'Customer Information') || (item.visitor && 'Public Visitor') || (item.contact && 'Contact Address') || 'No Contact'}
                            <span style={{marginLeft: '1em', textTransform: 'capitalize', color: statusMesg==5 ? '#c31' : '#555', fontWeight: 'bold', fontSize: '1.1em'}}>{
                              item.sentStamp && formatDistance(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())
                              // item.sentStamp && formatRelative(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date())
                            }</span>
                          </div>
                        </Feed.Summary>
                        <Feed.Extra>
                          {item.conversations && item.conversations[0] && item.conversations[0].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />} 
                          <span>{(item.conversations && item.conversations[0] && (item.conversations[0].message && item.conversations[0].message.length>40 ? item.conversations[0].message.substr(0,40)+ '...' : item.conversations[0].message)) || (item.content && item.content.length>40 ? item.content.substr(0,40)+ '...' : item.content)}</span>
                          {item.conversations.length>1 && <>
                            <Divider style={{margin: '.3em 0'}} horizontal><Icon name='ellipsis vertical' color='yellow' style={{cursor: 'pointer', margin: '0'}} onClick={this.props.openModal.bind(this, 'chatbox-outbound', item)}/></Divider>
                            {item.conversations && item.conversations[item.conversations.length-1] && item.conversations[item.conversations.length-1].sender=='agent' ? <Icon color='blue' name='angle double left' /> : <Icon color='red' name='angle double right' />}
                            <span><b>{(item.conversations && item.conversations[item.conversations.length-1] && (item.conversations[item.conversations.length-1].message && item.conversations[item.conversations.length-1].message.length>40 ? item.conversations[item.conversations.length-1].message.substr(0,40)+ '...' : item.conversations[item.conversations.length-1].message))}</b></span>
                          </>}
                        </Feed.Extra>
                        <Feed.Extra>
                          <div style={{display: 'flex'}}>
                            <div style={{flex: 1}}>
                              <Feed.Date style={{fontWeight: 'bold', fontSize: '.8em'}}>
                                {(item.clickStamp && format(parse(item.clickStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.readStamp && format(parse(item.readStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM ')) || (item.sentStamp && format(parse(item.sentStamp, 'yyyy-MM-dd HH:mm:ss', new Date()),'HH:mm, dd/MMM '))}
                              </Feed.Date>
                            </div>
                            <div style={{margin: '-1em 0 0 .5em'}}>
                              <Feed.Date>
                                <Popup content={(statusMesg==5 && 'Reply Now !') || (statusMesg==4 && 'Clicked')  || (statusMesg==3 && 'Read') || (statusMesg==2 && 'Delivered') || 'Sent'} position='left center' inverted trigger={
                                  <Icon className={(statusMesg==5 && 'blinkfast') || ''} style={{margin: '0', fontSize: statusMesg==5 ? '1.3em': '1.1em', cursor: 'pointer'}} onClick={this.props.openModal.bind(this, 'chatbox-outbound', item)}
                                    name={(statusMesg==5 && 'alarm') || (statusMesg==4 && 'eye')  || (statusMesg==3 && 'eye') || (statusMesg<=2 && 'check square') || 'times'} 
                                    color={(statusMesg==5 && 'red') || (statusMesg==4 && 'brown') || (statusMesg==3 && 'brown') || (statusMesg==2 && 'blue') || 'grey'} />
                                } />
                              </Feed.Date>
                            </div>
                          </div>
                        </Feed.Extra>
                      </Feed.Content>
                    </Feed.Event>
                  )
                }) : <b><Icon name='circle notch' loading />loading ...</b>
              }
          </Feed>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagingOutboundRecent)
