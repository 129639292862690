import React, { Component } from 'react'
import { Container, Icon, Button, Divider, Menu, Grid, Input, Popup, Segment, Header, Label, Dropdown } from 'semantic-ui-react'

import ModalContainer from '../../libraries/common/ModalContainer';
import SignoutConfirmation from '../../libraries/realm/halo/SignoutConfirmation';

import {withRouter} from "react-router-dom"

import { connect } from "react-redux"
import { signOut, checkToken } from "../../actions/auth/authAction"
import { socketStatus, socketWhatsappConnection } from "../../actions/socket/socketAction"


//CONNECT REDUX STORE
const mapStateToProps = (state, props) => {
  return {
    authStore: state.authStore,
    socketStore: state.socketStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut:(objParam) => dispatch(signOut(objParam)),
    checkToken:(objParam) => dispatch(checkToken(objParam)),

    socketStatus:(objParam) => dispatch(socketStatus(objParam)),
    socketWhatsappConnection:(objParam) => dispatch(socketWhatsappConnection(objParam)),
  }
}

const stylePopup = {
  // borderRadius: '0',
  // opacity: 1,
  // padding: '.5em .8em',
  // fontSize: '1em',
  // background: '#000',
  // color: '#fff'
}
const styleButton={
  margin: '.3em 0em',
  border: 'none',
}


class WhatsappHeader extends Component {
  constructor(props) {
    super(props);
    this.state={
      signoutModal: false,
      signoutReasong: null,
    }
  }
  
  openDrawer() {
    this.props.openDrawer()
  }

  doSignOut(values) {
    this.props.signOut(values)
    this.props.history.push('/signin')
  }

  openWindow(value) {
    this.props.openWindow(value)
  }

  componentDidMount() {
    const tokenAuth= localStorage.getItem('tokenAuth');
    if (tokenAuth==undefined || tokenAuth=='') {
      this.props.history.push('/signin')
    }
  }

  componentDidUpdate() {
    if (localStorage.getItem('tokenAuth')=='') {
      this.props.history.push('/signin');
    }
  }

  closeModal() {
    this.setState({
      signoutModal: false,
      signoutReason: null,
    })
    this.props.closeModal();
  }

  onChangeSelect(e, v) {
    console.log(v.value)
    this.setState({
      signoutReason: v.value,
    })
  }
  
  render() {
    const { screenWidth,  screenHeight, mini }= this.props
    const { code, data }= this.props.authStore && this.props.authStore.checkToken ? this.props.authStore.checkToken : {}

    const socketStatus= (this.props.socketStore.socketStatus!==undefined ? this.props.socketStore.socketStatus.data : undefined)
    const waConnnections= (this.props.socketStore.socketWhatsappConnection!==undefined ? this.props.socketStore.socketWhatsappConnection.data.payload.data : undefined)

    const onlines= [], offlines= [];
    waConnnections && waConnnections.provider.mobiles && waConnnections.provider.mobiles.map(item=>{
      if (item.online=='1') {
        onlines.push(item.account.replace('62', '0') +'_'+ item.serverId +'@'+ item.container)
      } else {
        offlines.push(item.account.replace('62', '0') +'_'+ item.serverId +'@'+ item.container)
      }
    })

    return (
      <div>
        <Menu fixed='top'  style={{padding: '0', border: 'none', background : 'none'}}>
          <div style={{display: 'flex', width: '100%', background : '#e1d9d2'}}>
            <div style={{padding: '.3em 0 0 .3em', textAlign: 'center'}}>
              <Popup position= 'bottom left' 
                trigger={
                // <Button onClick={this.props.openModal.bind(this,'setting')} basic={this.props.modal!='setting'} color={this.props.modal=='setting' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.modal=='setting' ? '#000' : '#fff'}} /></Button>
                  <Label as='a' style={{border: 'none', background: 'none', padding: '.2em 0 .5em .5em'}} onClick={this.openDrawer.bind(this)}>
                    <Icon circular inverted name='lightbulb' style={{fontSize: '1.3em', margin: '0', transform: 'rotate(90deg)'}} color={this.props.modal=='information' ? 'blue' : 'yellow'} onClick={this.props.openModal.bind(this,'information')}/>
                  </Label>
                }
                content='Information Board, for your best practice targeted campaigns and highest returns.'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{padding: '1em 0 0 1em'}}>
              <Header as='h5' color='black'>
                <Header.Content>
                  <b>Whatsapp Interaction</b>
                </Header.Content>
              </Header>
            </div>
            <div style={{flex: '1', margin: '.7em 0 0 1em'}}>
              {onlines.length>0 && <div style={{display: 'inline-block'}} onClick={this.props.openModal.bind(this,'sender')}>
                  <Label as='a' style={{padding: '.4em .5em .3em .7em'}} color='blue'>
                    <Icon name={'check square'} style={{margin: '0 .5em 0 0'}} />
                    | ONLINE
                  </Label>
                  <Label as='a' style={{padding: '.4em .5em .3em .5em'}} color='blue'>
                    {onlines.length>1 ? onlines.length +' ACC' : onlines.join(', ')}
                  </Label>
                  {offlines.length>0 && 
                    <Label as='a' style={{padding: '.4em .5em .3em .5em'}} color='grey' onClick={this.props.openModal.bind(this,'sender')}>
                      {offlines.length +' !'}
                    </Label>
                  }
                </div>
              }
              {onlines.length==0 && <>
                  <Label as='a' style={{padding: '.4em .5em .3em .5em'}} color='grey' onClick={this.props.openModal.bind(this,'sender')} >
                    <Icon name={'times rectangle'} style={{margin: '0 .5em 0 0'}} />
                    | OFFLINE
                  </Label>
                  <Label as='a' style={{padding: '.4em .5em .3em .5em'}} color='grey' onClick={this.props.openModal.bind(this,'sender')}>
                    {offlines.length>1 ? offlines.length +' !' : offlines.join(', ')}
                  </Label>
                </>
              }
            </div>
            {data && data.groupId!='Controller' && <>
              <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
                <Popup position= 'bottom right' 
                  trigger={
                    <Button onClick={this.props.openModal.bind(this,'conversation')} basic={this.props.modal!='conversation'} color={this.props.modal=='conversation'? 'yellow' : null} style={{...styleButton, padding: '.5em .3em .35em .3em'}} icon><Icon name='plus circle' style={{fontSize: '1.4em', color: this.props.modal=='conversation' ? '#000' : '#dc00bb'}}/></Button>}
                  content='New Conversation'
                  inverted
                  on={['hover']}
                />
              </div>
            </>}
            {/* <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'tip')} basic={this.props.modal!='tip'} color={this.props.modal=='tip'? 'yellow' : null} style={{...styleButton, padding: '.5em .3em .35em .3em'}} icon><Icon name='lightbulb' style={{fontSize: '1.4em', color: this.props.modal=='tip' ? '#000' : '#dc00bb'}}/></Button>}
                content='Marketing Tip'
                inverted
                on={['hover']}
              />
            </div> */}
            <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'tour')} basic={this.props.modal!='tour'} color={this.props.modal=='tour'? 'yellow' : null} style={{...styleButton, padding: '.5em .3em .35em .3em'}} icon><Icon name='info circle' style={{fontSize: '1.4em', color: this.props.modal=='tour' ? '#000' : '#dc00bb'}}/></Button>}
                content='Tour App / Service'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'profile')} basic={this.props.modal!='profile'} color={this.props.modal=='profile'? 'yellow' : null} style={styleButton} icon><Icon name='user' style={{fontSize: '.9em', color: this.props.modal=='profile' ? '#000' : '#666'}}/></Button>}
                content='My Account'
                inverted
                on={['hover']}
              />
              {/* <Popup style={stylePopup}
                trigger={<Button onClick={this.openWindow.bind(this,'account')} basic={this.props.mode!='account'} color={this.props.mode=='account'? 'yellow' : null} style={styleButton} icon><Icon name='user' style={{color: this.props.mode=='account' ? '#000' : '#666'}}/></Button>}
                content='Account'
                inverted
                on={['hover']}
              /> */}
              <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'message')} basic={this.props.modal!='message'} color={this.props.modal=='message'? 'yellow' : null} style={styleButton} icon><Icon name='bell' style={{color: this.props.modal=='message' ? '#000' : '#666'}} /> <span style={{color: this.props.modal=='message' ? '#000' : '#666'}}></span></Button>}
                content='Notification'
                inverted
                on={['hover']}
              />
            </div>
            <div style={{textAlign: 'right', paddingTop: '.25em', marginRight: '1em'}}>
              {/* <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'private')} basic={this.props.modal!='private'} color={this.props.modal=='private' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.modal=='private' ? '#000' : '#666'}} /></Button>}
                content='Setting'
                inverted
                on={['hover']}
              /> */}
              {/* <Popup position= 'bottom right' 
                trigger={<Button onClick={this.props.openModal.bind(this,'account')} basic={this.props.modal!='account'} color={this.props.modal=='account' ? 'yellow' : null} style={styleButton} icon><Icon name='user circle' style={{color: this.props.modal=='account' ? '#000' : '#666'}} /></Button>}
                header='Agents'
                content='Manage Agents Account, create, enabled or disabled agent, your seat occupancy bills will be based on your agent status.'
                inverted
                on={['hover']}
              /> */}

              {data && data.groupId=='Client' && <>
                <Popup position= 'bottom right' 
                  trigger={<Button onClick={this.props.openModal.bind(this,'billing')} basic={this.props.modal!='billing'} color={this.props.modal=='billing'? 'yellow' : null} style={styleButton} icon><Icon name='file alternate outline' style={{color: this.props.modal=='billing' ? '#000' : '#666'}} /> <span style={{color: this.props.modal=='billing' ? '#000' : '#666'}}></span></Button>}
                  content='Billing'
                  inverted
                  on={['hover']}
                />
                {/* <Popup position= 'bottom right' 
                  trigger={<Button onClick={this.props.openModal.bind(this,'api')} basic={this.props.modal!='api'} color={this.props.modal=='api'? 'yellow' : null} style={styleButton} icon><Icon name='rocket' style={{fontSize: '1em', color: this.props.modal=='api' ? '#000' : '#666'}}/></Button>}
                  content='API Sandbox for testing, sample API codes.'
                  inverted
                  on={['hover']}
                /> */}
                <Popup position= 'bottom right' 
                  trigger={<Button onClick={this.props.openModal.bind(this,'setting')} basic={this.props.modal!='setting'} color={this.props.modal=='setting' ? 'yellow' : null} style={styleButton} icon><Icon name='setting' style={{color: this.props.modal=='setting' ? '#000' : '#666'}} /></Button>}
                  header='Setting'
                  content='Set custom preference for your outbound campaign behaviour, make sure you khow how.'
                  inverted
                  on={['hover']}
                />
              </>}
              <Popup position= 'bottom right' 
                trigger={
                  <Button circular size='tiny'
                  //onClick={()=>this.doSignOut()} 
                  onClick={()=>this.setState({signoutModal: true})} 
                  color={this.props.mode=='signout'? 'yellow' : 'orange'} style={{...styleButton, marginLeft: '3em'}} icon><Icon name='sign-out' style={{color: this.props.mode=='signout' ? '#000' : '#fff'}} /></Button>
                }
                content='Signout'
                inverted
                on={['hover']}
              />
            </div>
          </div>
        </Menu>
        {this.state.signoutModal && <ModalContainer size='mini' content={
          <SignoutConfirmation onClick={()=>this.doSignOut({actionReason: this.state.signoutReason})} onChangeSelect={this.onChangeSelect.bind(this)} state={this.state}/>
        } closeModal={this.closeModal.bind(this)} /> }
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WhatsappHeader))