import React, { Component } from 'react';
import { Segment, Accordion, Divider, Popup, Button, Header, Image, Modal, Form, Input, Grid, Card, Icon } from 'semantic-ui-react';

import DismissableMessage from '../common/DismissableMessage';
import IconToggle from '../common/IconToggle';
import {genderOptions, maritalOptions, occupationOptions, revenueOptions, expenseOptions, homestayOptions, vehicleOptions}  from '../common/StaticMasterData';

import { SubmissionError, Field, reduxForm, getFormValues, formValueSelector } from 'redux-form';
import {ImageUploadField, LabelInputField, InputField, CheckboxField, NumberField, DatePickerField, SelectField } from '../validation/componentrsui';
import { required, confirmedPassword, phoneNumberID, number, minValue, email } from '../validation/validation';

import { connect } from "react-redux";
import { resetTarget, getTarget,updateTarget, createTarget } from "../../actions/stream/targetAction";

//CONNECT REDUX STORE
const selector = formValueSelector('TargetForm')

const mapStateToProps = (state, props) => {
  return {
    targetStore: state.targetStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetTarget:(objParam) => dispatch(resetTarget(objParam)),
    getTarget:(objParam) => dispatch(getTarget(objParam)),
    createTarget:(objParam) => dispatch(createTarget(objParam)),
    updateTarget:(objParam) => dispatch(updateTarget(objParam)),
  }
}

class TargetForm extends Component {
  constructor(props) {
    super(props)
    this.state= {

      showContract:false,
      showOptional:false,
    }
  }

  componentDidMount(){
    this.props.resetTarget(this.props.mode)
    if (this.props.mode==2) { //update
      this.props.getTarget({targetId: this.props.targetId})
    }
  }

  componentDidUpdate() {
    const { mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {}) : (this.props.targetStore.createTarget!=undefined ? this.props.targetStore.createTarget : {}))
    if (progress==1 && code=='00') {
      setTimeout(()=>{this.props.setMode(1)}, 1000 )
    }
  }

  resetForm() {
    const { reset } = this.props
    reset()
  }

  onSubmit(values) {
    const { mode } = this.props
    // console.log('!!! SUBMIT: ', values)
    mode==2 ? this.props.updateTarget({...values, name: this.props.custName}) : this.props.createTarget({...values, ...(this.props.campaignId ? {campaignId: this.props.campaignId} : {}), name: this.props.custName})
    
  }

  showOptional(v) {
    if (isNaN(v)) {
      this.setState({
        showContract: v=='contract' ? !this.state.showContract : this.state.showContract,
      });
    } else {
      this.setState({
        showOptional: (v ==this.state.showOptional) ? 0 : v,
      });
    }
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, mode } = this.props
    const { progress, code, text }= (mode==2 ? (this.props.targetStore.updateTarget!=undefined ? this.props.targetStore.updateTarget : {}) : (this.props.targetStore.createTarget!=undefined ? this.props.targetStore.createTarget : {}))
    
    const channels= (this.props.campaign && this.props.campaign.channels) || this.props.channels || [];

    const scripts= (this.props.campaign && this.props.campaign.scriptExt && !Array.isArray(this.props.campaign.scriptExt) && this.props.campaign.scriptExt.split(/({[a-zA-Z]*})/)) || (this.props.scriptExt && !Array.isArray(this.props.scriptExt) && this.props.scriptExt.split(/({[a-zA-Z]*})/))
    const vars= new Map()
    scripts && scripts.map(item=>{
        item.match(/{[a-zA-Z]*}/) && !vars.get(item) && vars.set(item)
    })
    let fields= [];

    console.log(scripts)
    console.log([...vars.keys()])
    vars.delete('{custName}');

    return (
      <div style={{padding: '1em 1em'}}>
        <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Segment>
            {false && <div>
              <Form.Group widths='equal' >
                <Form.Field>
                  <Field name='avatar' component={ImageUploadField}
                  width={200} 
                  height={200} 
                  aspectRation={1}
                  />
                </Form.Field>
              </Form.Group>
              <Divider hidden/>
            </div>}
            
            <Form.Group widths='16'>
              <Field name='isEnabled' component={CheckboxField}
              width={8}
              toggle
              caption='Enabled'
              label='Enable Now !'
              placeholder='Status enable/disabled' />
              {/* <Field name='owner' component={InputField}
              readOnly
              width={4}
              label='Owner'
              placeholder='Owner' />
              <Field name='client' component={InputField}
              readOnly
              width={4}
              label='Client'
              placeholder='Client' /> */}
            </Form.Group>
            <Form.Group widths='16'>
            {(!this.props.campaign || mode==2) && <>
              <Field name='campaign' component={InputField}
              readOnly
              width={8}
              label='Campaign'
              placeholder='Campaign' />
              <Field name='campaignType' component={InputField}
              readOnly
              width={8}
              label='Campaign Type'
              placeholder='Campaign Type' />
              {/* <Field name='channels' component={InputField}
              readOnly
              width={4}
              label='Channels'
              placeholder='Channels' /> */}
            </>}
            {mode==3 && this.props.campaign && this.props.campaign.name && <>
              <Form.Input value={this.props.campaign.name} readOnly fluid width={8} />
              <Form.Input value={this.props.campaign.campaignType} readOnly fluid width={4} />
              <Form.Input value={this.props.campaign.channels} readOnly fluid width={4} />
            </>}
            </Form.Group>
          </Segment>

          <Segment>
            <Form.Group widths='16'>
              <Field name='whatsapp' component={InputField}
              validate={[required, phoneNumberID]}
              width={8}
              // validate={[required]}
              label='Whatsapp'
              placeholder='Whatsapp Number' />
              <Field name='custName' component={InputField}
              width={8}
              // validate={[required]}
              label='Name'
              placeholder='Fullname' />
            </Form.Group>
            {
              vars && [...vars.keys()].map((item, idx)=>{
                fields[idx]= <Field name={item.replace(/\{|\}/gi, '')} component={InputField}
                // validate={[required]}
                label={item}
                placeholder={'Variable '+ item.replace(/\{|\}/gi, '')} />;

                if ([...vars.keys()].length==(idx+1) || idx%2==1) {
                  if (idx%2==1) {
                    return(
                      <Form.Group widths='equal'>
                        {fields[idx-1]} {fields[idx]}
                      </Form.Group>      
                    )
                  } else {
                    return(
                      <Form.Group widths='equal'>
                        {fields[idx]}
                      </Form.Group>      
                    )
                  }
                }
              })
            }
          </Segment>

          <Segment>
            {/* <Divider hidden/> */}
            {(progress==1 || progress==2)  && <DismissableMessage style={{marginBottom: '1em'}} mode={this.props.mode} header={(mode==2 ? 'Update Contact' : 'Insert Contact') + ' - ' + (progress==1 && code=='00' ? 'OK' : 'NOK')} content={'(' + code + ') ' + text}/>}
            
            <div style={{textAlign: 'right'}}>
              <Button type='reset' size='small' content=' Reset ' color='grey' disabled={this.props.pristine || this.props.submitting} onClick={this.resetForm.bind(this)} />
              <Button type='submit' size='small' color='red' disabled={pristine || progress==0 } icon={progress==0 ? true : false}>{progress==0 ? <Icon name='notch circle' loading /> : ''} Submit</Button>
            </div>
          </Segment>
        </Form>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
(
  connect(
    state => ({
      initialValues: (state.targetStore.getTarget!=undefined && state.targetStore.getTarget.progress==1 ? state.targetStore.getTarget.data : {isEnabled: '1'}),
      formValues: getFormValues('TargetForm')(state),
      enableReinitialize: true, 
      keepDirtyOnReinitialize: false,
      keepSubmitSucceeded:true,

      custName: selector(state, 'custName'),
      scriptExt: selector(state, 'scriptExt'),

      campaignType: selector(state, 'campaignType'),
      channels: selector(state, 'channels'),
    }),
    { load: getTarget }
  )
  (reduxForm({
    form: 'TargetForm',	// a unique identifier for this form
  })(TargetForm)
  )
)
