import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Popup,
  Statistic,
  Progress,
} from 'semantic-ui-react'

import MessagingInboundRecent from '../../libraries/whatsapp/MessagingInboundRecent';
import MessagingOutboundRecent from '../../libraries/whatsapp/MessagingOutboundRecent';
import AgentWidget from '../../libraries/widget/whatsapp/AgentWidget';
import BillingWidget from '../../libraries/widget/whatsapp/BillingWidget';
import CampaignWidget from '../../libraries/widget/whatsapp/CampaignWidget';
import MessagingWidget from '../../libraries/widget/whatsapp/MessagingWidget';
import CampaignBody from './CampaignBody';


class DashboardBody extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        mode: 1,
      };
    }

    setMode(mode, id) {
      this.setState({mode: mode, campaignId: id})
    }

    render() {
      const {mode, campaignId}= this.state
      const {screenWidth, screenHeight, module}= this.props

      return(
        <Container fluid>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', background: '#f8f8f8', padding: '2em 1.5em'}}>
              <Card.Group>
                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_campaign')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <div style={{display: 'flex', minHeight: '100%'}}>
                      <div style={{width: 250, background: '#f8f8f8', minHeight: '100%'}}>
                        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
                          <div style={{marginBottom: '1.5em'}}>
                            <Header as='h5'>
                              <b><i>Active!</i> Campaign</b>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Header as='h2' style={{margin: '0'}}>
                              <Icon name='calendar alternate outline' style={{fontSize: '2em'}} />
                              <Header.Content>
                                <b>1234</b>
                                <Header.Subheader>
                                  <b>Active Campaign</b>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Divider style={{margin: '2em 0'}} />
                            <Header as='h2' style={{margin: '0'}}>
                              <Icon name='user circle' style={{fontSize: '2em'}} />
                              <Header.Content>
                                <b>1234</b>
                                <Header.Subheader>
                                  <b>Tot. Target</b>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                        </div>
                      </div>
                      <div style={{flex: 1, background: '#f0f0f0', minHeight: '100%', padding: '.5em 0'}}>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>SENT</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>DELIVERED</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>READ</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>RESPONSED</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>

                        <Divider />
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>BLOCKED</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>FAILED</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                      </div>
                      <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            100%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Success Rate</Statistic.Label>
                        </Statistic>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            180%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Response Rate</Statistic.Label>
                        </Statistic>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            30%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>Blocking Rate</Statistic.Label>
                        </Statistic>
                      </div>
                    </div>
                  </Card.Content>
                </Card>

                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_message')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <div style={{display: 'flex', minHeight: '100%'}}>
                      <div style={{width: 250, background: '#eaeaea', minHeight: '100%'}}>
                        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
                          <div style={{marginBottom: '1.5em'}}>
                            <Header as='h5'>
                              <b><i>Today!</i> Messaging</b>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Header as='h2' style={{margin: '0'}}>
                              <Icon name='angle double right' style={{fontSize: '2em'}} />
                              <Header.Content>
                                <b>1234</b>
                                <Header.Subheader>
                                  <b>Tot. Inbound</b>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Divider style={{margin: '1.5em 0'}} />
                            <Header as='h2' style={{margin: '0'}}>
                              <Icon name='angle double left' style={{fontSize: '2em'}} />
                              <Header.Content>
                                <b>1234</b>
                                <Header.Subheader>
                                  <b>Tot. Outbound</b>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                        </div>
                      </div>
                      <div style={{background: '#f0f0f0', flex: 1, minHeight: '100%', padding: '.5em 0'}}>
                        <div style={{display: 'flex', flexDirection: 'column', minHeight: '100%'}}>
                          <div style={{flex: '1'}}>
                            <div style={{display: 'flex', textAlign: 'center'}}>
                              <div style={{flex: '1'}}>
                                <Header as='h3' icon>
                                  <Icon name='thermometer half' style={{fontSize: '1.6em'}} circular inverted color='orange' />
                                  <Header.Content>
                                    121313
                                    <Header.Subheader>ON QUEUE / WAITING</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </div>
                              <div style={{flex: '1'}}>
                                <Header as='h3' icon>
                                  <Icon name='podcast' style={{fontSize: '1.6em'}} circular inverted color='yellow' />
                                  <Header.Content>
                                    121313
                                    <Header.Subheader>QUICK CHAT / BY AGENT</Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div style={{display: 'flex', margin: '.5em 1em'}}>
                              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>OPEN</b></Label>
                              </div>
                              <div style={{flex: '1'}}>
                                <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                              </div>
                            </div>
                            <div style={{display: 'flex', margin: '.5em 1em'}}>
                              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>PROGRESS</b></Label>
                              </div>
                              <div style={{flex: '1'}}>
                                <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                              </div>
                            </div>
                            <div style={{display: 'flex', margin: '.5em 1em'}}>
                              <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                                <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>CLOSED</b></Label>
                              </div>
                              <div style={{flex: '1'}}>
                                <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            100%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. WAITING</Statistic.Label>
                        </Statistic>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            180%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. SESSION</Statistic.Label>
                        </Statistic>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            30%
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>AVG. RESPONSE</Statistic.Label>
                        </Statistic>
                      </div>
                    </div>
                  </Card.Content>
                </Card>

                <Card fluid as='a' style={{padding: '1em', border: '1px dashed rgba(71, 185, 255, .8)', borderRadius: 0}} onClick={this.props.openModal.bind(this, 'performance_ticket')}>
                  <Card.Content style={{background: '#f5f5f5', padding: '0em'}}>
                    <div style={{display: 'flex', minHeight: '100%'}}>
                      <div style={{width: 250, background: '#f7f7f7', minHeight: '100%'}}>
                        <div style={{display: 'flex', padding: '1em 1em', flexDirection: 'column', minHeight: '100%'}}>
                          <div style={{marginBottom: '1.5em'}}>
                            <Header as='h5'>
                              <b><i>Today!</i> Ticket</b>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                            <Header as='h2' style={{margin: '0'}}>
                              <Icon name='ticket' style={{fontSize: '2em', transform: 'rotate(90deg)'}} />
                              <Header.Content>
                                <b>1234</b>
                                <Header.Subheader>
                                  <b>Tot. Ticket</b>
                                </Header.Subheader>
                              </Header.Content>
                            </Header>
                          </div>
                          <div style={{flex: '1'}}>
                          </div>
                        </div>
                      </div>
                      <div style={{flex: 1, background: '#f0f0f0', minHeight: '100%', padding: '.5em 0'}}>
                        <div style={{display: 'flex', margin: '.5em 1em'}}>
                          <div style={{paddingRight: '.5em', textAlign: 'right'}}>
                            <Label style={{borderRadius: '1em .3em .3em 1em', padding: '.6em 1em', width: '9em'}} size='medium' color='olive'><b>REMARK</b></Label>
                          </div>
                          <div style={{flex: '1'}}>
                            <Progress color='olive' size='medium' progress='value' value={10} total={1000} style={{margin: '0', borderRadius: '0 1em 1em 0'}} />
                          </div>
                        </div>
                      </div>
                      <div style={{width: '11em', textAlign:'center', padding: '.5em 0'}}>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            100K
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>NO REMARK</Statistic.Label>
                        </Statistic>
                        <Statistic size='mini' style={{margin: '.5em 0', width: '8em'}}>
                          <Statistic.Value style={{paddingBottom: '.5em', borderBottom: '1px solid #ccc'}}>
                            54
                          </Statistic.Value>
                          <Statistic.Label style={{fontSize: '.9em', borderTop: '1px solid #fff', textAlign: 'center'}}>WITH REMARK</Statistic.Label>
                        </Statistic>
                      </div>
                    </div>
                  </Card.Content>
                </Card>
              </Card.Group>
            </div>
            <div style={{width: 350, background: '#f5f5f5', padding: '2em 1.5em'}}>
              <MessagingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} modal={this.props.modal}/>
              <CampaignWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <AgentWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
              <BillingWidget openModal={this.props.openModal.bind(this)} screenWidth={screenWidth} screenHeight={screenHeight-125} />
            </div>
          </div>
        </Container>
      )
    }
}

export default DashboardBody
